import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

export default class extends Controller {

  connect(){
    this.showModal()
  }

  submitEnd(e) {
    if (e.detail.success) { // cuando el form tiene errores el controller coloca status :unprocessable_entity y esto es false
      this.hideModal()
    }
  }

  showModal(){
    this.modal = new bootstrap.Modal(this.element)
    this.modal.show()
  }

  hideModal(){
    this.modal.hide()
  }

}
