import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"

// Ajusta el tamaño el font-size para que el texto entre en el elemento
// Ejemplo:
// <input type="text" data-controller="text-fit" "data-fit-text-default-size-value="5" data-fit-text-unit-value="rem" data-fit-text-step-value="0.1">
// data-controller="fit-text"
// data-fit-text-default-size-value="20" (required)
// data-fit-text-unit-value="px" (required)
// data-fit-text-step-value="1" (required)
// data-fit-text-ignore-height-value="true" (optional)
export default class extends Controller {

  static values = {
    defaultSize: Number,
    unit: String,
    step: Number,
    ignoreHeight: Boolean
  }

  initialize() {
    Loggable(this, {debug: false})
  }

  connect() {
    this.resize()
  }

  resize() {
    // Retrieve the dimensions of the element.
    const el = this.element
    const elWidth = el.offsetWidth
    const elHeight = el.offsetHeight

    // Set the initial font size to a default value.
    let fontSize = this.defaultSizeValue

    // Use a loop to decrease the font size by 1 pixel at a time until the text fits within the element.
    while (fontSize > 1) {
      el.style.fontSize = `${fontSize}${this.unitValue}`
      if (el.scrollWidth <= elWidth && (this.ignoreHeightValue || el.scrollHeight <= elHeight)) {
        break
      }
      fontSize -= this.stepValue
    }

    // Set the font size to the last value that fit.
    el.style.fontSize = `${fontSize}${this.unitValue}`
  }
}
