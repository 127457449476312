import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"

// When connected, registers a service worker.
// @see https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerContainer/register
export default class extends Controller {

  connect() {
    Loggable(this, { debug: false })
    this.registerServiceWorker()
  }

  registerServiceWorker(){
    if (!navigator.serviceWorker) {
      this.log("service worker not supported")
      return
    }

    // @see https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerContainer/register
    // "You can call this method unconditionally from the controlled page. I.e., you don't need to first check whether there's an active registration.
    this.log("registering service worker")
    navigator.serviceWorker.register('/service-worker.js')
      .then(registration => {
        this.log("service worker registered")
        this.log(registration)
      }).catch(error => {
        this.error("service worker registration failed")
        this.error(error)
      })
  }

  unregisterServiceWorkers(){
    if (!navigator.serviceWorker) {
      this.log("service worker not supported")
      return
    }

    this.log("unregistering service worker")
    navigator.serviceWorker.getRegistrations()
      .then(registrations => {
        registrations.forEach(registration => {
          this.log("unregistering service worker")
          registration.unregister()
            .then(success => {
              this.log("service worker unregistered")
            }).catch(error => {
            this.error("service worker unregistration failed")
            this.error(error)
          })
        })
      }).catch(error => {
      this.error("service worker unregistration failed")
      this.error(error)
    })
  }


}
