import { Application } from "@hotwired/stimulus"

// https://docs.appsignal.com/front-end/integrations/stimulus.html
import { appsignal } from "./appsignal";
import { installErrorHandler } from "@appsignal/stimulus";

const application = Application.start()
installErrorHandler(appsignal, application);

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }


