import { Controller } from "@hotwired/stimulus"

/*
  filter-toggler.text target debe tener:
    data-status-text-off-inactive
    data-status-text-off-active
    data-status-text-on
 */
export default class extends Controller {

  static targets = [ "button", "text" ]

  initialize(){
    this.log("initialize")
  }
  connect(){
    this.log("connect")
    this.updateButton()
  }

  toggleForm(){
    if(this.data.get("status")=="off") {
      this.data.set("status","on")
    } else {
      this.data.set("status","off")
    }
    this.updateButton()
    $(this.filtersForm()).toggleClass("hiddenUnder")
  }

  updateButton(){
    this.copyFilterFormActive()
    if(this.data.get("status")=="off") {
      if(this.data.get("filtersActive") == "true") {
        this.textTarget.innerHTML = this.textTarget.dataset.statusTextOffActive
      } else {
        this.textTarget.innerHTML = this.textTarget.dataset.statusTextOffInactive
      }
    } else {
      this.textTarget.innerHTML = this.textTarget.dataset.statusTextOn
    }
  }

  copyFilterFormActive(){
    this.data.set("filtersActive", this.filtersForm().dataset.filtersActive)
  }

  // El formulario de filtros no está dentro del scope del controller entonces lo busco por ID
  filtersForm(){
    return document.getElementById("filters")
  }

  log(msg){
    //console.log("[filter_toggler] " + msg)
  }

}
