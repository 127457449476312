import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"
import * as bootstrap from "bootstrap"

// Usage:
// <div data-controller="tooltip" title="xxx">
export default class extends Controller {

  initialize() {
    Loggable(this, { debug: false })
  }

  connect() {
    this.log("connected")
  }

  show(){
    this.modal = new bootstrap.Modal(this.element.dataset.bsTarget)
    this.modal.show()
  }

  hide(){
    this.modal.hide()
  }
}
