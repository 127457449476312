import { Controller } from "@hotwired/stimulus"
import _ from "underscore"
import { Loggable } from "./concerns/loggable"

// en el FORM data-controller="auto-submit"
// en los inputs data-action="auto-submit#debouncedSubmit"
export default class extends Controller {

  static targets = ["loading"]

  initialize(){
    Loggable(this, {debug: false})
  }

  connect() {
    this.debouncedSubmit = _.debounce(this.debouncedSubmit.bind(this), 300)
    this.log("connected")
  }

  debouncedSubmit(){
    this.log("debouncedSubmit")
    this.submit()
  }

  submit(){
    this.log("submit")
    if(this.element.dataset.remote) {
      this.log("form is remote")
      this.remoteSubmit()
    } else {
      this.log("form is not remote")
      this.element.requestSubmit()
    }
    if (this.hasLoadingTarget) {
      this.loadingTarget.classList.remove("d-none")
    }
  }

  remoteSubmit(){
    // Create a new FormData object from the form
    let formData = new FormData(this.element);

    // Use fetch API to submit the form data
    fetch(this.element.action, {
      method: this.element.method,
      body: formData,
      headers: {
        'X-Requested-With': 'XMLHttpRequest', // This makes the request as AJAX
        'Accept': 'application/json'
      }
    }).then(response => response.json())
      .catch(error => this.log('Error:', error))
      .then(data => {
        // Handle the response data here
        this.log("response:", data);
      })
  }

  // hide loadingTarget on successful form submission
  // action: "turbo:submit-end->auto-submit#submitEnd"
  submitEnd(e) {
    this.log("submitEnd")
    if (e.detail.success) {
      if (this.hasLoadingTarget) {
        this.loadingTarget.classList.add("d-none")
      }
    }
  }

}
