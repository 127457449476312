export const usePwaHelpers = (controller) => {

  // check user-agent for safari on ios
  const isIos = () => {
    let ua = window.navigator.userAgent;
    let iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    let webkit = !!ua.match(/WebKit/i);
    let safari = !!ua.match(/Safari/i);
    let notChrome = !ua.match(/CriOS/i);
    let notFirefox = !ua.match(/FxiOS/i);
    return iOS && webkit && safari && notChrome && notFirefox;
  }

  const isInstalled = () => {
    if (isIos()){
      return window.navigator.standalone === true;
    } else {
      return (window.matchMedia('(display-mode: standalone)').matches);
    }
  }

  const isAppleDesktop = () => {
    // check if it's a mac
    let userAgent = navigator.userAgent.toLowerCase();
    if (navigator.maxTouchPoints || !userAgent.match(/macintosh/))
      return false;
    // check safari version >= 17
    const version = /version\/(\d{2})\./.exec(userAgent)
    if (!version || !version[1] || !(parseInt(version[1]) >= 17))
      return false;
    // hacky way to detect Sonoma
    let audioCheck = document.createElement('audio').canPlayType('audio/wav; codecs="1"') ? true : false;
    let webGLCheck = new OffscreenCanvas(1, 1).getContext('webgl') ? true : false;

    return audioCheck && webGLCheck;
  }

  // check user-agent for chrome on android
  const isAndroidPhone = () => {
    let ua = window.navigator.userAgent;
    let android = !!ua.match(/Android/i);
    let chrome = !!ua.match(/Chrome/i);
    return android && chrome;
  }

  const canInstall = () => {
    if (isIos()){
      return 'standalone' in window.navigator;
    } else {
      return isAndroidPhone() || isAppleDesktop();
    }
  }

  // agrego los métodos al controller
  Object.assign(controller, { isIos, isAppleDesktop, isAndroidPhone, canInstall, isInstalled })
}
