import AutocheckinController from "./autocheckin_controller";

export default class extends AutocheckinController {
  static targets = [ ]

  // flag de que estoy ejecutando un checkin en este momento
  checkingIn = false

  silentCheckin(event) {
    let origUrl = this.data.get("checkinUrl")

    if (!event.detail || !event.detail.elapsedSeconds) {
      // do normal silent checkin
      super.silentCheckin();
    } else if (event.detail.elapsedSeconds > 60) {
      // if elapsedSeconds > 60, do a checkin with the new elapsedSeconds
      let newUrl = this.addElapsedSecondsToUrl(this.data.get("checkinUrl"), event.detail.elapsedSeconds)
      this.data.set("checkinUrl", newUrl)

      // do checkin with new data.
      super.silentCheckin();

      // restore original url to avoid duplication if user checkins again.
      this.data.set("checkinUrl", origUrl)
    } else {
      // dont checkin if elapsedSeconds < 60
    }
  }

  addElapsedSecondsToUrl(url, elapsedSeconds) {
    const ret = this.ensureFullUrl(url)
    ret.searchParams.append('elapsed_seconds', elapsedSeconds)
    return ret.toString()
  }

  // url podría ser solo un path y en ese caso URL() falla
  ensureFullUrl(url){
    try {
      return new URL(url);
    } catch (e) {
      return new URL(`${window.location.origin}${url}`);
    }
  }
}
