import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static sigPad = null
  static targets = [ "pad", "pointsField", "dataurlField"]

  initialize(){
  }
  connect(){
    this.initSignaturePad()
  }

  signaturePad(){
    if(!this.sigPad){
      this.sigPad = new SignaturePad(this.padTarget);
    }
    return this.sigPad
  }

  initSignaturePad(){
    this.signaturePad() // init pad
    if (this.hasPointsFieldTarget){
      if(this.pointsFieldTarget.value!=""){
        this.signaturePad().fromData(JSON.parse(this.pointsFieldTarget.value))
        this.data.set("empty",this.signaturePad().isEmpty())
      }
    }
    this.signaturePad().addEventListener("endStroke", () => {
      this.storeSignature()
    })
  }

  storeSignature(){
    this.log("storing signature points")
    if (this.hasPointsFieldTarget){
      this.pointsFieldTarget.value = JSON.stringify(this.signaturePad().toData())
    }
    this.dataurlFieldTarget.value = this.signaturePad().toDataURL()
    this.data.set("empty",this.signaturePad().isEmpty())
  }

  resetPad(event){
    if(event){
      event.preventDefault();
    }
    if (this.hasPointsFieldTarget){
      this.pointsFieldTarget.value = ""
    }
    this.dataurlFieldTarget.value = ""
    this.signaturePad().clear()
    this.data.set("empty",this.signaturePad().isEmpty())
  }

  log(msg){
    console.log("[signature_pad] "+msg)
  }

}
