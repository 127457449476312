import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"

export default class extends Controller {

  static targets = ["container", "input"]

  connect() {
    Loggable(this, {debug: false})
    this.log("connected")
  }

  preview(event) {
    this.containerTarget.innerHTML = "";

    let files = this.inputTarget.files;

    for(let i = 0; i < files.length; i++) {
      let file = files[i]

      // Create a new FileReader instance
      const reader = new FileReader();

      // When the file has been read...
      reader.onload = (e) => {
        // Create a new image element
        const img = document.createElement('img');

        // Set the source of the image to the result of the FileReader
        img.src = e.target.result;

        // Append the image to the container target
        this.containerTarget.appendChild(img);
      };

      // Start reading the file as a DataURL
      reader.readAsDataURL(file);
    }
  }

}
