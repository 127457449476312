import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"
import { usePwaHelpers } from "./concerns/use_pwa_helpers";

// en el link poner data-controller="ios-pwa-open-in-safari"
// si estoy en ios y tengo la app instalada y la url es ssl y es de padma, le agrega x-safari- al href para que abra en safari y no dentro de la PWA
export default class extends Controller {
  initialize() {
    Loggable(this, { debug: false })
    usePwaHelpers(this)
  }

  connect() {
    this.log("connected")
    if (this.isIos() && this.isInstalled() && this.hrefIsSsl && this.hrefIsPADMA()){
      this.log("adding x-safari- to href")
      this.element.href = "x-safari-" + this.element.href
    }
  }

  hrefIsSsl(){
    return this.element.href.includes("https://")
  }

  hrefIsPADMA(){
    return this.element.href.includes("crm.padm.am") || this.element.href.includes("crm.derose.app")
  }

}
