import { Controller } from "@hotwired/stimulus"
import { CsrfTokenable } from "./concerns/csrf-tokenable";

export default class extends Controller {

  static targets = [ "email", "code", "emailMissingMessage", "deliverCodeButton"]

  initialize(){
    CsrfTokenable(this)
    this.toggleDeliverCodeButton()
  }
  connect(){
    if (this.codeTarget.value != "") {
      this.validate()
    }
  }

  // envía el código al email
  deliver(event){
    this.log("send auth code")
    this.codeTarget.value = "" // reset input
    event.preventDefault();
    if ( this.emailTarget.value == "") {
      alert("email required, input email");
    } else {
      $(event.target).hide()
      fetch(this.data.get("sendUrl")+"?email="+this.emailTarget.value, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.getCsrfToken()
        },
        redirect: 'follow'
      }).then(response => {
        $(event.target).show() // re-show link to send code
        if (response.ok) {
          alert("code sent!")
        } else {
          alert("error sending code")
        }
      })
    }
  }

  // muestra el boton para solicitar código si corresponde
  toggleDeliverCodeButton(){
    if ( this.emailTarget.value === "" ) {
      this.emailMissingMessageTarget.style.display = "block"
      this.deliverCodeButtonTarget.style.display = "none"
    } else {
      this.emailMissingMessageTarget.style.display = "none"
      this.deliverCodeButtonTarget.style.display = "block"
    }
  }

  // valida el código ingresado
  validate(event){
    if ( this.emailTarget.value == "" ) {
      this.data.set("valid", "false")
      alert("missing email")
    } else if ( this.codeTarget.value == "" ) {
      this.data.set("valid", "false")
      alert("missing code")
    } else {
      let url = this.data.get("checkUrl")
      url += "?email=" + this.emailTarget.value
      url += "&code=" + this.codeTarget.value
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.getCsrfToken()
        },
        redirect: 'follow'
      }).then(response => {
        if (response.ok) {
          this.data.set("valid", "true")
        } else {
          this.data.set("valid", "false")
          this.codeTarget.value = ""
          alert("code invalid")
        }
      })
    }
  }

  log(msg){
    console.log("[auth_code] "+msg)
  }

}
