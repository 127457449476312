import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "button" ]

  connect(){
    this.toggleCallToAction()
  }

  /* Oculto el CTA si ya se ven en pantalla los botones de pago */
  toggleCallToAction(){
    if(this.saleButtonsAreVisible() && this.hasButtonTarget){
      $(this.buttonTarget).hide()
    } else {
      $(this.buttonTarget).show()
    }
  }

  saleButtonsAreVisible(){
    var el = document.querySelector("#ticket-sale-container")
    var bounding = el.getBoundingClientRect()

    return bounding.top <= ((window.innerHeight || document.documentElement.clientHeight) - 100)
  }

}
