import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"

export default class extends Controller {

  static targets = ["avatar", "body"]

  connect() {
    Loggable(this, { debug: false })
    this.log("connected")

    if (this.hasAvatarTarget && this.hasBodyTarget) {
      this.avatarTarget.style.bottom = (this.bodyTarget.clientHeight - (this.avatarTarget.clientHeight / 2)) + "px"
    }
  }

}
