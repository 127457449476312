import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "form", "userSelect", "stopSeeingAsLink" ]

  initialize(){
  }
  connect(){
  }

  seeAsUser(){
    if(this.userSelectTarget.value==""){
      if(this.hasStopSeeingAsLinkTarget){
        this.stopSeeingAsLinkTarget.click()
      }
    } else {
      this.submitForm()
    }
  }

  submitForm(){
    this.formTarget.submit()
  }
}
