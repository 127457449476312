import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"
import Lightbox from 'bs5-lightbox';

export default class extends Controller {

  static values = { gallery: String }

  connect() {
    Loggable(this, { debug: false })

    this.lightbox = new Lightbox(this.element, {
      keyboard: true,
      size: "lg",
      gallery: this.galleryValue,
      constrain: true
    })
  }

  show(e){
    e.preventDefault()
    this.lightbox.show();
  }

}
