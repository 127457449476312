import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"

// This controller is used to set the color scheme of the page
export default class extends Controller {

  static values = {
    mode: String
  }

  initialize() {
    Loggable(this, { debug: false })
  }

  connect() {
    this.registerListenEvent()
    this.setTheme()
    this.log("connected")
  }

  disconnect() {
    this.deregisterListenEvent()
    this.log("disconnected")
  }

  setTheme() {
    if (this.modeValue) {
      switch(this.modeValue) {
        case "auto":
          this.setAuto()
          break
        case "dark":
        case "light":
          this.element.dataset.bsTheme = this.modeValue
          break
        default:
          this.element.dataset.bsTheme = "light"
          break
      }
    }
  }

  setDark() {
    this.element.dataset.bsTheme = "dark"
  }

  setLight(){
    this.element.dataset.bsTheme = "light"
  }

  setAuto(){
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      // dark mode
      this.element.dataset.bsTheme = "dark"
    } else {
      this.element.dataset.bsTheme = "light"
    }
  }
  registerListenEvent(){
    window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", e => e.matches && this.setTheme())
    window.matchMedia("(prefers-color-scheme: light)").addEventListener("change", e => e.matches && this.setTheme())
    window.matchMedia("(prefers-color-scheme: dark)").addListener(e => e.matches && this.setTheme())
    window.matchMedia("(prefers-color-scheme: light)").addListener(e => e.matches && this.setTheme())
  }

  deregisterListenEvent(){
    window.matchMedia("(prefers-color-scheme: dark)").removeEventListener("change", e => e.matches && this.setTheme())
    window.matchMedia("(prefers-color-scheme: light)").removeEventListener("change", e => e.matches && this.setTheme())
    window.matchMedia("(prefers-color-scheme: dark)").removeListener(e => e.matches && this.setTheme())
    window.matchMedia("(prefers-color-scheme: light)").removeListener(e => e.matches && this.setTheme())
  }
}
