import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"
import { CsrfTokenable } from "./concerns/csrf-tokenable";

// This controller is used to set the color scheme of the page
export default class extends Controller {

  static values = {
    objectName: String,
    url: String
  }

  static targets = ["button"]

  initialize() {
    Loggable(this, { debug: false })
    CsrfTokenable(this)
  }

  connect() {
    // default object name is "user"
    this.objectNameValue = this.objectNameValue || "user"

    this.log("connected")
  }

  highlightSelection(event){
    this.log("highlighting selection")
    this.buttonTargets.forEach(button => {
      button.classList.remove("current-mode")
    })
    event.currentTarget.classList.add("current-mode")
  }

  setTheme(newTheme) {
    this.log("setting theme")
    document.querySelector("html").dataset.bsTheme = newTheme
  }

  setDark(e) {
    this.setTheme("dark")
    this.highlightSelection(e)
    this.updateUserTheme("dark")
  }

  setLight(e){
    this.setTheme("light")
    this.highlightSelection(e)
    this.updateUserTheme("light")
  }

  setAuto(e){
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      // dark mode
      this.setTheme("dark")
    } else {
      this.setTheme("light")
    }
    this.highlightSelection(e)
    this.updateUserTheme("auto")
  }

  updateUserTheme(newMode){
    this.log("updating user theme")
    this.log(this.urlValue)
    let body = {}
    body[this.objectNameValue] = { color_scheme: newMode }
    fetch(this.urlValue, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': this.getCsrfToken()
      },
      body: JSON.stringify(body)
    })
  }

}
