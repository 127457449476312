import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "entry" ]

  initialize(){
    this.log("initialized")
  }
  connect(){
    this.log("conected")
  }

  updateFilter(event){
    let target = event.target
    let showType = target.dataset.option
    this.entryTargets.forEach((e) => {
      if ( e.classList.contains(showType) ) {
        $(e).show()
      } else {
        $(e).hide()
      }
    })
  }

  log(msg){
    console.log("[weekly-quick-filter] "+msg)
  }
}
