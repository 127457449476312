import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"

export default class extends Controller {

  connect() {
    Loggable(this, {debug: false})
    this.log("connected")
    setTimeout(() => this.adjustHeight(), 1000)
  }

  adjustHeight() {
    this.log("adjusting height")
    let newHeight = this.element.contentWindow.document.documentElement.scrollHeight + 'px';
    this.log("new height: " + newHeight)
    this.element.height = newHeight
  }

}
