import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"
import { ShowHide } from "./concerns/showHide"

export default class extends Controller {
  initialize() {
    Loggable(this, { debug: true })
    ShowHide(this)
  }

  connect() {
    if (!navigator.geolocation){
      this.log("Geolocation is not supported by your browser")
      this.hide(this.element)
    }
    this.log("connected")
  }

  filterNearMe(event){
    event.preventDefault()
    navigator.geolocation.getCurrentPosition((position) => {
      this.log("Latitude: " + position.coords.latitude + " Longitude: " + position.coords.longitude)
      // reload with coordinates[latitude],coordinates[longitude] in the URL
      Turbo.visit(window.location.href.split('?')[0] + `?coordinates[latitude]=${position.coords.latitude}&coordinates[longitude]=${position.coords.longitude}&kms=${event.params.kms}`)
    })
  }


}
