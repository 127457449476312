import { Controller } from "@hotwired/stimulus"

/*
  Cuando estan presentes Live y InPersonLive equivalentes
  elimino el Live del DOM
  Sin mover ni copiar nada.
 */
export default class extends Controller {

  static targets = [ "entry" ]

  initialize(){
    this.log("initialized")
  }
  connect(){
    this.log("connected")
    this.hideCounterparts()
  }

  hideCounterparts(){
    this.log("hiding counterparts")
    this.entryTargets.forEach((e) => {
      if ( e.dataset.counterpartId && e.dataset.postType=="InPersonLive" ) {
        this.log(e.dataset.postId+" has counterpart")
        if(this.counterpartFor(e)){
          this.log("hiding")
          this.hideCounterpart(e)
        } else {
          this.log("counterpart not found")
        }
      } else {
        this.log(e.dataset.postId + "NO counterpart")
      }
    })
  }

  hideCounterpart(entry){
    this.counterpartFor(entry).remove()
  }

  counterpartFor(entry){
    return this.entryTargets.filter((e)=>{ return e.dataset.postId==entry.dataset.counterpartId })[0]
  }

  log(msg){
    console.log("[merge-hybrids] "+msg)
  }
}
