import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"

export default class extends Controller {

  static values = {
    clear: Boolean,
    currentToken: String,
    url: String
  }

  connect() {
    Loggable(this, {debug: false})
    this.log("connected")
    if(this.clearValue){
      this.clearBackupToken()
    } else {
      if(this.isPwaInstalled()){
        if(this.hasCurrentTokenValue){
          this.backupCurrentToken()
        } else if (this.getBackupToken()) {
          this.restoreSession()
        }
      }
    }

  }

  isPwaInstalled() {
    if ("standalone" in window.navigator) {
      return window.navigator.standalone === true;
    } else {
      return (window.matchMedia('(display-mode: standalone)').matches);
    }
  }

  restoreSession(){
    this.log("restoring session")
    Turbo.visit(`${this.urlValue}?cst=${this.getBackupToken()}`)
  }

  backupCurrentToken(){
    this.log("backing up current token")
    localStorage.setItem("cst", this.currentTokenValue)
  }

  getBackupToken(){
    return localStorage.getItem("cst")
  }

  clearBackupToken(){
    this.log("clearing backup token")
    localStorage.removeItem("cst")
  }

}
