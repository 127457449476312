import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"
import Holder from "holderjs";

export default class extends Controller {
  static targets = ["entries", "pagination"]

  initialize() {
    Loggable(this, {debug: false})
  }

  connect(){
  }

  scroll(){
    if (document.body.scrollTop > 15 || document.documentElement.scrollTop > 15) {
      this.shrink()
    } else {
      this.unshrink()
    }
  }

  shrink(){
    if (this.state === "shrinked") {
      return
    }
    this.log("shrinking")
    document.getElementsByTagName("body")[0].classList.add("shrinked");
    document.getElementById("main-navigation").classList.add("shrinked");
    document.getElementById("navbarLogo").classList.add("shrinked");

    let bodySubNavbar = document.getElementById("body-sub-navbar")
    if (bodySubNavbar) {
      bodySubNavbar.classList.add("shrinked");
    }
    this.state = "shrinked"
  }

  unshrink(){
    if (this.state === "unshrinked") {
      return
    }
    this.log("unshrinking")
    document.getElementsByTagName("body")[0].classList.remove("shrinked");
    document.getElementById("main-navigation").classList.remove("shrinked");
    document.getElementById("navbarLogo").classList.remove("shrinked");
    let bodySubNavbar = document.getElementById("body-sub-navbar")
    if (bodySubNavbar) {
      bodySubNavbar.classList.remove("shrinked");
    }
    this.state = "unshrinked"
  }

  scrolled

}
