import IntervalController from "./interval_controller"

// Este es un intervalo para ásana. Tiene imágen y puede tener compensación
// cycle sería un lado. Full cycle sería ambos lados. (si hay compensación)
export default class extends IntervalController {

  static targets = [
    "compensation",
  ]
  static values = {
    imageId: String,
    strLeft: String,
    strRight: String,
  }

  connect() {
    super.connect()
    this.renderDuration()
    if (this.hasImageIdValue) {
      this.imageElement = document.getElementById(this.imageIdValue)
    }
  }

  start(){
    super.start()
  }

  continue(){
    super.continue()
  }

  finish(){
    this.hideImage()
    super.finish()
  }

  showImage(){
    if (this.imageElement){
      this.show(this.imageElement)

      if (this.hasCompensation() && this.isLastCycle()) {
        this.imageElement.classList.add("mirror-x")
      } else {
        this.imageElement.classList.remove("mirror-x")
      }
    }
  }

  hideImage(){
    if (this.imageElement){
      this.hide(this.imageElement)
    }
  }

  initClicks(){
    super.initClicks()
    this._cycleClick = 0
    this._currentCycle = 0 // left/right
  }

  initDurations(){
    this._cycleDuration = parseInt(this.durationMinutesTarget.value) * 60 + parseInt(this.durationSecondsTarget.value)
    this._totalDuration = this.cyclesForFullCycle() * this._cycleDuration
    document.dispatchEvent(new CustomEvent("interval:durationChanged"))
  }
  
  incClick(){
    super.incClick()
    this._cycleClick = Math.floor(this.currentClick % this._cycleDuration)
    this._currentCycle = this.calculateCurrentCycle()
  }

  playNeededAudio(){
    if (this.isOverDuration()) {
      // sound played in playlist
    } else if (this.currentClick === 0 && this.playlistOutlet.hasAudio("start")) {
      this.playlistOutlet.playAudio("start")
    } else if (this.isFirstClickOfCycle() && !this.isFirstCycle()) {
      this.playlistOutlet.playAudio("metronomeChange")
    } else if (this.playClickValue) {
      this.playlistOutlet.playAudio("click")
    }
  }

  render(){
    super.render()

    // acá suele estar el reloj más grande.
    this.playlistOutlet.clickMessageTarget.innerText = ""

    this.showImage()
  }

  // renders elapsedTime in CYCLE (left/right)
  renderElapsedTime() {
    let humanClick = this._cycleClick + 1
    let message = `${Math.floor(humanClick / 60)}:${(humanClick % 60).toString().padStart(2, '0')}`
    message += ` / ${Math.floor(this._totalDuration / 60)}:${(this._cycleDuration % 60).toString().padStart(2, '0')}`
    this.playlistOutlet.elapsedTimeMessageTarget.innerText = message
  }

  renderIntervalName() {
    // leave interval name empty
    this.playlistOutlet.intervalNameTarget.innerText = ""
  }

  calculateCurrentCycle(){
    return Math.floor(this.currentClick / this._cycleDuration)
  }

  cyclesForFullCycle(){
    if (this.hasCompensation()) {
      return 2
    } else {
      return 1
    }
  }

  isFirstClickOfCycle(){
    return this._cycleClick === 0
  }

  isLastClickOfCycle(){
    return this._cycleClick === this._cycleDuration - 1
  }

  isFirstCycle(){
    return this.calculateCurrentCycle() == 0
  }

  isLastCycle(){
    return  this.calculateCurrentCycle() == (this.cyclesForFullCycle() - 1)
  }

  // TODO no está haciendo left/right
  actionMessage(){
    if (this.hasCompensation()){
      switch (this.calculateCurrentCycle()){
        case 0:
          return `${this.nameValue} ${this.strLeftValue}`
        case 1:
          return `${this.nameValue} ${this.strRightValue}`
      }
    } else {
      return this.nameValue
    }
  }

  nextActionMessage() {
    if (this.hasCompensation()){
      switch (this.calculateCurrentCycle() + 1){
        case 0:
          return `${this.nameValue} ${this.strLeftValue}`
        case 1:
          return `${this.nameValue} ${this.strRightValue}`
      }
    } else {
      return this.nameValue
    }
  }

  hasCompensation(){
    if (this.compensationTarget.type == "checkbox"){
      return this.compensationTarget.checked
    } else {
      return this.compensationTarget.value == "1"
    }
  }

  hasNextAction(){
    return !this.isLastCycle()
  }

}
