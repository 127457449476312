import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "toggler", "form" ]

  connect(){
    this.formTarget.style.display = "none"
    if ( this.data.get("coupon") != "" ) {
      this.showForm()
    }
  }

  showForm(event){
    if (event) {
      event.preventDefault();
    }
    this.togglerTarget.style.display = "none"
    this.formTarget.style.display = "block"
  }

}
