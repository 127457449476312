import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static sigPad = null
  static godSigPad = null
  static targets = [ "signature", "email", "emailCopy", "authCodeController" ]

  initialize(){
  }
  connect(){
    this.checkTouchEnabled()
    this.initBootstrapValidation()
    this.copyEmail()
  }

  checkTouchEnabled(){
    if ( !this.isTouchEnabled() ) {
      $(this.openOnMobileQRContainerTarget).show()
    } else {
      $(this.openOnMobileQRContainerTarget).hide()
    }
  }

  initBootstrapValidation(){
    window.addEventListener('load', () => {
      // Fetch all the forms we want to apply custom Bootstrap validation styles to
      let forms = document.getElementsByClassName('needs-validation');
      // Loop over them and prevent submission
      let validation = Array.prototype.filter.call(forms, function(form) {
        form.addEventListener('submit', function(event) {
          if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
          }
          form.classList.add('was-validated');
        }, false);
      });
    }, false);
  }

  validateSignature(event){
    if( !this.isSigned() ){
      alert(this.data.get("signature-missing-msg"))
      event.preventDefault()
    }
  }

  isSigned(){
    return (this.signatureTarget.dataset.signaturePadEmpty == "false")
  }

  validate2FA(event){
    if ( !this.is2FAuthenticated() ){
      alert(this.data.get("2fa-missing-msg"))
      event.preventDefault()
    }
  }

  isTouchEnabled() {
    return ( 'ontouchstart' in window ) || ( navigator.maxTouchPoints > 0 ) || ( navigator.msMaxTouchPoints > 0 );
  }

  is2FAuthenticated(){
    return this.authCodeControllerTarget.dataset.authCodeValid == "true";
  }

  copyEmail(event) {
    this.emailCopyTarget.value = this.emailTarget.value
  }

  log(msg){
    console.log("[form_matricula] "+msg)
  }

}
