import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"

export default class extends Controller {
  connect() {
    Loggable(this, {debug: false})
    this.element.querySelectorAll("a").forEach((link) => {
      if (link.dataset.turboFrame === "" || !link.hasAttribute("data-turbo-frame")) {
        link.dataset.turboFrame = "_top"
      }
    })
    this.log("connected")
  }

}
