import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"
import { CsrfTokenable } from "./concerns/csrf-tokenable"
import { ShowHide } from "./concerns/showHide";
import { Timestampable } from "./concerns/timestampable";

// guarda la current position en el servidor cada 180 segundos
// y ante los eventos beforeunload y turbo:before-visit (ver html)
export default class extends Controller {

  static values = {
    postId: String,
    currentPosition: Number,
    lastServerPosition: Number,
    maxPosition: Number
  }

  static targets = ["returnToMaxPosition", "maxPositionTimestamp"]

  static outlets = ["video-timestamp", "share-w-timestamp"]

  // guardar position cada 180 segundos
  // con 60s estaba en 52% de impacto (mean 52ms) en el reporte performance de Appsignal (2oct2024)
  updatePositionEveryUpdatesCount = 180

  initialize() {
    Loggable(this, { debug: false })
    CsrfTokenable(this)
    ShowHide(this)
    Timestampable(this)
  }

  connect() {
    this.toggleReturnToMaxPosition()
    this.refreshShare()
    this.log("connected")
  }

  tickUpdateSession(timeUpdatesCount, data){
    this.log("tickUpdateSession", timeUpdatesCount, data)

    this.updateDOMSession(data)

    if (this.shouldUpdateServerSession(timeUpdatesCount)){
      this.updateServerSession(data)
    }
    this.toggleReturnToMaxPosition()
  }

  toggleReturnToMaxPosition(){
    this.log("toggleReturnToMaxPosition", this.currentPositionValue, this.maxPositionValue)
    if (this.currentPositionValue < this.maxPositionValue){
      this.show(this.returnToMaxPositionTarget)
    } else {
      this.hide(this.returnToMaxPositionTarget)
    }
  }

  refreshMaxPosition(){
    if (this.currentPositionValue > this.maxPositionValue){
      this.maxPositionValue = this.currentPositionValue
      this.videoTimestampOutlet.timestampValue = this.secondsToTimestamp(this.maxPositionValue)
      this.maxPositionTimestampTarget.innerHTML = this.secondsToTimestamp(this.maxPositionValue)
    }
  }

  updateDOMSession(data){
    this.log("updateDOMSession", data)
    this.currentPositionValue = data.position
    this.refreshMaxPosition()
    this.refreshShare()
  }

  refreshShare(){
    if (this.currentPositionValue){
      this.shareWTimestampOutlet.timestampInputTarget.value = this.secondsToTimestamp(this.currentPositionValue)
    }
  }

  updateServerSession(data){
    this.log("updateServerSession", data)
    fetch(`/posts/${this.postIdValue}/post_open_session`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.getCsrfToken()
      },
      body: JSON.stringify({
        post_open_session: data
      })
    })
    .then(response => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error("Network response was not ok")
      }
    })
    .then(json => {
      this.lastServerPositionValue = data.position
      this.log("updateServerSession response", json)
    })
    .catch(error => {
      this.log("updateServerSession error")
      this.error(error)
    })
  }

  // called from action in html
  updateServerWithCurrentPosition(){
    this.log("updateServerWithCurrentPosition")
    if (this.currentPositionValue !== this.lastServerPositionValue){
      this.updateServerSession({ position: this.currentPositionValue })
    }
  }

  destroySession(){
    this.log("destroySession")
    this.lastServerPositionValue = this.currentPositionValue // para que no se actualice en el server
    fetch(`/posts/${this.postIdValue}/post_open_session`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.getCsrfToken()
      }
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error("Network response was not ok")
        }
      })
      .then(json => {
        this.log("destroySession response", json)
      })
      .catch(error => {
        this.log("destroySession error")
        this.error(error)
      })
  }

  shouldUpdateServerSession(timeUpdatesCount){
    this.log("shouldUpdateServerSession")

    return timeUpdatesCount > 0 && (timeUpdatesCount % this.updatePositionEveryUpdatesCount) === 0
  }

}
