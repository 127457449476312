import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {

  static targets = [ "payButton" ]

  initialize() {
  }
  connect() {
    this.log("controller connected")
    this.connectToChannel()
  }

  connectToChannel(){
    this.log("connecting to channel payment_notifications:"+this.data.get("preference-id"))
    let controller = this
    consumer.subscriptions.create(
      {
        channel: "PaymentNotificationsChannel",
        preference_id: controller.data.get("preference-id")
      },
      {
      connected() {
        controller.log("channel connected")
      },
      disconnected() {
        controller.log("channel disconnected")
      },
      received(data) {
        controller.notifyPayment(data)
      }
    })
  }

  notifyPayment(data){
    this.log(data.message)
    this.payButtonTarget.innerHTML = data.message
    if (this.data.get("alert") === "true") {
      alert(data.message)
    }
  }

  log(msg){
    console.log("[payment_notification] "+msg)
  }

}
