import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "entry" ]

  initialize(){
    this.log("initialized")
  }
  connect(){
    this.hideCounterparts()
    this.log("connected")
  }

  hideCounterparts(){
    this.entryTargets.forEach((e) => {
      if ( e.dataset.counterpartId && e.dataset.postType=="InPersonLive" ) {
        this.log(e.dataset.postId+" has counterpart")
        if(this.counterpartFor(e)){
          this.log("hiding")
          this.setHybridCssClass(e)
          this.movePlayer(this.counterpartFor(e),e)
          this.hideCounterpart(e)
        } else {
          this.log("counterpart not found")
        }
      }
    })
  }

  setHybridCssClass(entry){
    entry.classList.remove("InPersonLive")
    entry.classList.remove("Live")
    entry.classList.add("Hybrid")
  }

  showHybridLabel(entry){
    let label =  entry.getElementsByClassName("entry--label")[0]
    if(label){
      label.innerHTML = this.data.get("hybridLabel")
    }
  }

  hideCounterpart(entry){
    this.counterpartFor(entry).remove()
  }

  movePlayer(source_entry,target_entry){
    let sources_player = source_entry.getElementsByClassName("entry--player")[0]
    let targets_player = target_entry.getElementsByClassName("entry--player")[0]

    $(sources_player).insertAfter(targets_player)
  }

  counterpartFor(entry){
    return this.entryTargets.filter((e)=>{ return e.dataset.postId==entry.dataset.counterpartId && e.dataset.wday==entry.dataset.wday })[0]
  }

  log(msg){
    console.log("[weekly-hybrids] "+msg)
  }
}
