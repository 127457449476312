import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"

export default class extends Controller {
  connect() {
    Loggable(this, {debug: false})
    this.log("connected")
  }

}
