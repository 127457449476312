import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"
import { ShowHide }   from "./concerns/showHide";

export default class extends Controller {

  static targets = ["replyForm", "toggleCommentButton"]

  static values = {}

  static outlets = []

  connect() {
    Loggable(this, {debug: false})
    ShowHide(this)
    this.log("connected")
  }

  toggleReply(event){
    event.preventDefault()
    if (this.hasToggleCommentButtonTarget) {
      this.toggle(this.toggleCommentButtonTarget)
    }
    this.toggle(this.replyFormTarget)
    this.replyFormTarget.querySelector("textarea").focus()
  }

}
