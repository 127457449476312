import { Controller } from "@hotwired/stimulus"
import Cookie from "js.cookie/dst/Cookie";

/*
  This controller is used to auto save fields values on cookies
   to restore if the user closes the browser before submitting the form.

   Los valores son identificados por el id (dom) del campo.

   Si se indica la option "clearFields" se borran todos los valores de los fields de la cookie.
   data-auto-save-clear-fields-value=true
 */
export default class extends Controller {

  static targets = [ "resetButtonContainer", "field" ]

  static values = {
    clearFields: Boolean
  }

  hasSavedFields = false

  initialize(){
    this.log("initialize")
  }
  connect(){
    this.log("connect")
    if (this.clearFieldsValue) {
      this.clearSavedValues()
    } else {
      this.loadValues()
      this.initAutoSave()
    }
  }


  loadValues(){
    this.fieldTargets.forEach((field) => {
      this.loadField(field)
      this.showResetButton()
    })
  }

  initAutoSave(){
    this.fieldTargets.forEach((field) => {
      field.addEventListener("change", () => {
        this.saveField(field)
      })
    })
  }

  // Save the value of the field in the cookie
  // value expires in 7 days by default
  saveField(field, options = {expires: 7, recursive: true}) {
    this.log("saveField: "+field.id)

    if(options["recursive"] && field.matches("[type='radio']")){
      // los radios solo disporan 'change' en sí mismos pero cambian el valor de los otros radios
      document.getElementsByName(field.name).forEach((radio) => {
        options["recursive"] = false // avoid infinite loop
        this.saveField(radio, options)
      })
    }

    if(field.matches("[type='checkbox'], [type='radio']")){
      Cookie.set(field.id, field.checked, { expires: options["expires"] })
    } else {
      Cookie.set(field.id, field.value, { expires: options["expires"] })
    }
  }

  loadField(field) {
    this.log("loadField: " +field.id)
    let savedValue = Cookie.get(field.id)

    if (savedValue) {
      this.hasSavedFields = true
      this.setField(field, savedValue)
    }

  }

  // Clear value of fields AND remove from cookies
  // Hides reset button
  resetFields(){
    this.log("resetFields")
    this.fieldTargets.forEach((field) => {
      if(field.matches("[type='checkbox'], [type='radio']")){
        this.setField(field, false)
      } else {
        this.setField(field, "")
      }
      Cookie.remove(field.id)
    })
    this.hideResetButton()
  }

  clearSavedValues(){
    this.log("clearSavedValues")
    this.fieldTargets.forEach((field) => {
      Cookie.remove(field.id)
    })
  }

  setField(field, value){
    if(field.matches("[type='checkbox'], [type='radio']")){
      field.checked = value
    } else {
      field.value = value
    }

    // trigger 'change' event (not automatic when setting via script on hidden field)
    if ("createEvent" in document) {
      let evt = document.createEvent("HTMLEvents");
      evt.initEvent("change", false, true);
      field.dispatchEvent(evt);
    } else {
      field.fireEvent("onchange")
    }
  }

  showResetButton(){
    if ( this.hasResetButtonContainerTarget && this.hasSavedFields ) {
      this.resetButtonContainerTarget.classList.remove("hidden")
    }
  }

  hideResetButton(){
    if ( this.hasResetButtonContainerTarget) {
      this.resetButtonContainerTarget.classList.add("hidden")
    }
  }

  log(msg){
    // console.log("[auto-save] "+msg)
  }

}
