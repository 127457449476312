import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"

export default class extends Controller {

  static values = { aspectRatio: Number }
  static targets = [ "item" ]

  connect() {
    Loggable(this, { debug: true })

    this.resize()

    this.log("connected")
  }

  resize() {
    this.log("resize")
    this.itemTargets.forEach((item) => {
      item.style.height = `${item.offsetWidth * this.aspectRatioValue}px`
    })
  }

}
