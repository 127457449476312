import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"
import * as bootstrap from "bootstrap"

// Usage:
// <div data-controller="tooltip" title="xxx">
export default class extends Controller {

  initialize() {
    Loggable(this, { debug: false })
  }

  connect() {
    this.tooltip = new bootstrap.Tooltip(this.element, {
      html: true,
    })
    this.log("connected")
  }

  show(event) {
    event.preventDefault()
    this.tooltip.show()
    this.log("showTooltip")
  }
}
