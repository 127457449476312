import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"
import { ShowHide } from "./concerns/showHide";
import { post } from "@rails/request.js"
import * as bootstrap from "bootstrap"

import { Picker } from 'emoji-mart'
import data from '@emoji-mart/data'

export default class extends Controller {

  static values = {
    locale: String,
    reactableType: String,
    reactableId: String,
    theme: String
  }
  static targets = []

  initialize() {
    ShowHide(this)
    Loggable(this, { debug: false })
  }

  connect() {
    this.log("connecting")
    this.element.appendChild(new Picker({
      theme: this.themeValue,
      skinTonePosition: "none",
      locale: this.localeValue,
      onEmojiSelect: (emoji) => { this.selectedEmoji(emoji) }
    }))
    this.log("connected")
  }

  showPicker(event, reactableType, reactableId){
    this.reactableTypeValue = reactableType
    this.reactableIdValue = reactableId
    this.modal = new bootstrap.Modal(this.element.closest("div.modal"), {
      focus: true,
      keyboard: true,
    })
    this.modal.show()
  }

  selectedEmoji(emoji){
    this.log(emoji)
    this.hidePicker()
    this.postReaction(emoji)
  }

  postReaction(emoji){
    post("/reactions", {
      body: {
        reactable_type: this.reactableTypeValue,
        reactable_id: this.reactableIdValue,
        reaction: {
          emoji_id: emoji.id
        }
      },
      responseKind: "turbo-stream"
    })
  }


  hidePicker(){
    this.modal.hide()
  }

}
