import { Controller } from "@hotwired/stimulus"
import { VariousHelpers } from "./concerns/various_helpers"
import { tns } from 'tiny-slider/src/tiny-slider';

/*
  Controller for tiny-slider

  USAGE
    data-controller="slider" (required)
    data-slider-initialized="false" (required) slider hidden by css when false
    data-slider-current-index="0" (required)
    data-slider-loop="false" (optional) defaults to true
    data-slider-config="tiny-slider-options-JSON" (optional) @see https://ganlanyuan.github.io/tiny-slider/
    data-slider-prev-txt=".." (optional)
    data-slider-next-txt=".." (optional)
 */
export default class extends Controller {

  static targets = [ "tlContainer", "container", "prevButton", "nextButton" ]

  initialize(){
    VariousHelpers(this);

    this.initSlider();
    document.addEventListener("turbo:load", () => {
      this.initSlider();
    })

    this.slider.events.on("indexChanged", (info, eventName) => {
      this.data.set("currentIndex", info.index);
    });

    this.equalizeHeights();
    window.addEventListener("resize", () => {
      this.equalizeHeights();
    })

  }

  connect(){
  }

  initSlider(){
    this.log("initialize slider");

    this.hideButtonsOnTouch();

    if (this.data.get("initialized") == "true") {
      // avoid nesting on turbo reload
      let oldContainer = $(this.containerTarget).closest(".tns-outer")[0];
      if (oldContainer) {
        this.tlContainerTarget.appendChild(this.containerTarget);
        oldContainer.remove()
      }
    }

    this.slider = tns(this.tinySliderOptions());
    this.data.set("initialized", "true"); // con esto, css hace que se torne visible el slider
  }

  equalizeHeights(){
    this.log("equalizing heights");
    let maxHeight = 0;
    this.containerTarget.querySelectorAll(".card").forEach(card => {
      let c = card.querySelector(".flexContainer")
      c.style.height = null;
    })
    this.containerTarget.querySelectorAll(".card").forEach(card => {
      let c = card.querySelector(".flexContainer")
      if (c.offsetHeight > maxHeight) {
        maxHeight = c.offsetHeight;
      }
    })
    this.containerTarget.querySelectorAll(".card").forEach(card => {
      let c = card.querySelector(".flexContainer")
      c.style.height = maxHeight + "px";
    })
  }

  tinySliderOptions(){
    let config = {
      container: this.containerTarget,

      gutter: 0,
      autoHeight: true, // necesario si ningun post tiene cover

      freezable: false, // necesario para que funcione si cambian el tamaño de la pantalla y se freezaria
      slideBy: 1,
      startIndex: this.data.get("currentIndex"),

      preventScrollOnTouch: "auto",
      swipeAngle: 75,

      mouseDrag: true,
      arrowKeys: false,
      nav: false,
      loop: this.loopConfig(),

      controls: this.controlsConfig(),
      prevButton: this.prevButtonConfig(),
      nextButton: this.nextButtonConfig(),

      responsive: {
        // bootstrap breakpoint: xs: 0 , sm: 576, md: 768, lg: 992, xl: 1200
        0: {
          items: 2,
          edgePadding: 25,
        },
        768: {
          items: 3,
          edgePadding: 30,
        },
        992: {
          items: 3,
          edgePadding: 50,
        },
        1200: {
          items: 4,
          edgePadding: 50,
        },
        1400: {
          items: 4,
          edgePadding: 50,
        }
      }
    }

    // merge userConfig into defaultConfig
    let userConfig = this.data.get("config");
    if (userConfig) {
      userConfig = JSON.parse(userConfig);
      for (let attrname in userConfig) {
        if (attrname === "responsive") {
          for (let breakpoint in userConfig[attrname]) {
            for (let subAttrname in userConfig[attrname][breakpoint]) {
              config[attrname][breakpoint][subAttrname] = userConfig[attrname][breakpoint][subAttrname];
            }
          }
        } else {
          config[attrname] = userConfig[attrname];
        }
      }
    }

    return config;
  }

  hideButtonsOnTouch(){
    if (this.isTouchEnabled()){
      if (this.hasPrevButtonTarget){
        this.prevButtonTarget.style.display = "none";
      }
      if (this.hasPrevButtonTarget){
        this.nextButtonTarget.style.display = "none";
      }
      if (this.hasTlContainerTarget){
        this.tlContainerTarget.style.maxWidth = "100%";
      }
    }
  }

  controlsConfig(){
    return this.hasPrevButtonTarget && this.hasNextButtonTarget && !this.isTouchEnabled();
  }

  prevButtonConfig(){
    if (this.hasPrevButtonTarget && !this.isTouchEnabled()) {
      return this.prevButtonTarget;
    } else {
      return false;
    }
  }

  nextButtonConfig(){
    if (this.hasNextButtonTarget && !this.isTouchEnabled()) {
      return this.nextButtonTarget;
    } else {
      return false;
    }
  }

  loopConfig(){
    if(this.data.get("loop")){
      return this.toBool(this.data.get("loop"))
    } else  {
      return true
    }
  }

  toBool(val){
    return ["true",true,"1","yes"].includes(val)
  }

  log(msg){
    // console.log("[posts-slider] " + msg);
  }

}
