import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"
import { ShowHide } from "./concerns/showHide"

// para cuando el DOM me complica hacer algo responsive solo con CSS y preciso mover un elemento de un lugar a otro.
// por ejemplo, mover un elemento de una columna a otra en un layout de dos columnas.
// items and containers should have a data-responsive-mover-id
// container should have data-sizes
// - valid sizes are: xs, sm, md, lg, xl, xxl (bootstrap 5)
// - data-sizes can be:
//      a comma separated list of size. eg: xs,md,lg
//      a size range separated by a dash. eg: xs-md. This will apply to all sizes between xs and md (inclusive). Range can be open ended. eg: xs- will apply to all sizes from xs and up.
//      a combination of the above. eg: xs-md,lg-xxl
export default class extends Controller {

  static targets = ["container", "item"]

  initialize() {
    Loggable(this, { debug: false })
    ShowHide(this)
  }

  connect() {
    this.move()
    this.log("showing all", this.itemTargets)
    this.showAll(this.itemTargets)
    this.log("connected")
  }

  move(event){
    this.log("moving")
    this.itemTargets.forEach((item) => {
      this.log("item", item)
      const container = this.containerForCurrentSize(item.dataset.responsiveMoverId)
      if (container) {
        this.log("container", container)
        container.appendChild(item)
      } else {
        this.log("no container found for", item)
      }
    })
  }

  containerForCurrentSize(id) {
    const size = this.currentSize()
    this.log(`current size: ${size}`)
    const containers = this.containerTargets.filter((c) => {
      const sizes = this.getSizes(c)
      this.log("sizes for", c, sizes)
      return sizes.includes(size) && c.dataset.responsiveMoverId === id
    })
    return containers[0]
  }

  // returns an array of sizes for a container
  getSizes(container){
    let sizes = container.dataset.sizes.split(",").map((s) => {
      if (s.includes("-")) {
        let [min, max] = s.split("-")
        if (min === "") min = "xs"
        if (max === "") max = "xxl"
        const sizes = ["xs", "sm", "md", "lg", "xl", "xxl"]
        const minIndex = sizes.indexOf(min)
        const maxIndex = sizes.indexOf(max)
        return sizes.slice(minIndex, maxIndex + 1)
      } else {
        return s
      }
    })
    // flatten array
    sizes = [].concat.apply([], sizes)
    return sizes
  }

  // maps current screen size to a bootstrap size
  // xs: 0-575px, sm: 576-767px, md: 768-991px, lg: 992-1199px, xl: 1200-1399px, xxl: 1400px and up
  currentSize() {
    const sizes = ["xs", "sm", "md", "lg", "xl", "xxl"]
    const width = window.innerWidth
    let size = sizes[0]
    if (width >= 576) size = sizes[1]
    if (width >= 768) size = sizes[2]
    if (width >= 992) size = sizes[3]
    if (width >= 1200) size = sizes[4]
    if (width >= 1400) size = sizes[5]
    return size
  }

}
