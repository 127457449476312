import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"
import { SafeTimeoutable } from "./concerns/safe_timeoutable";

export default class extends Controller {
  static targets = [  ]

  initialize(){
    Loggable(this, {debug: false})
    SafeTimeoutable(this)
  }

  connect(){
    this.queueReload()
  }

  queueReload(){
    this.log("queueing reload in "+this.data.get("reloadEvery")+"ms.")

    this.safeSetTimeout(() => this.doReload(), this.data.get("reloadEvery") )
  }

  doReload(){
    this.log("reloading")
    Turbo.visit(location.toString())
  }
}
