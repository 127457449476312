import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "entry" ]

  initialize(){
    this.log("initialized")
  }
  connect(){
    this.alignEntries()
    this.log("connected")
  }

  alignEntries(){
    for(let i = parseInt(this.data.get("startHour")); i <= parseInt(this.data.get("endHour")); i++){
      this.alignHour(i)
    }
  }

  alignHour(hour){
    this.log("aligning hour "+hour)
    let top = this.YTopForhour(hour)
    this.entriesOnHour(hour).filter((e) => { return e.offsetTop < top }).forEach((e) => {
      this.moveDownEntryUntil(e, top)
    })
  }

  moveDownEntryUntil(entry, targetTop){
    if( (targetTop - entry.offsetTop) > 2 ){ // tolerance of 2px. si no a veces termina moviendo de más.
      let spacer = document.createElement("div")
      spacer.classList.add("weekly-schedule__spacer")
      let height = targetTop - entry.offsetTop - 10 // arrancar 10px antes si no a veces pifia.
      spacer.style.height = height+"px"
      entry.parentElement.insertBefore(spacer, entry)
      while(entry.offsetTop < targetTop){
        height++
        spacer.style.height = height+"px"
      }
      return spacer
    }
  }

  entriesOnHour(hour){
    return this.entryTargets.filter((e) => { return parseInt(e.dataset.startHour) == hour })
  }

  YTopForhour(hour){
    let maxOffset = this.maxTopForHourFirstsOnly(hour)
    let prevHourBottom = 0
    for(let prevHour = hour - 1; prevHourBottom <= 0 && (prevHour >= parseInt(this.data.get("startHour"))); prevHour--){
      prevHourBottom = this.YBottomForHour(prevHour)
    }
    return Math.max(prevHourBottom, maxOffset)
  }
  YBottomForHour(hour){
    let maxOffset = this.maxOffsetTopForHour(hour);
    let maxHeight = Math.max(...this.entriesOnHour(hour).filter((e) => { return e.offsetTop == maxOffset }).map((e) => { return e.offsetHeight }))
    return maxOffset + maxHeight
  }

  maxTopForHourFirstsOnly(hour){
    let hourEntries = this.entriesOnHour(hour)
    if(hourEntries.length > 0) {
      let firstEntriesTop = [0,1,2,3,4,5,6,7].map((wday) => {
        let wdayEntries = hourEntries.filter((e) => { return parseInt(e.dataset.wday) == wday })
        if(wdayEntries.length > 0){
          return wdayEntries[0].offsetTop
        } else {
          return 0
        }
      })
      return Math.max(...firstEntriesTop)
    } else  {
      return 0
    }
  }

  maxOffsetTopForHour(hour){
    return Math.max(...this.entriesOnHour(hour).map((e) => { return e.offsetTop }))
  }

  log(msg){
    console.log("[weekly-aligner] "+msg)
  }
}
