import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

// https://tom-select.js.org/
export default class extends Controller {

  static values = {
    allowCreate: Boolean,
    extraPlugins: Array,
  }

  connect() {

    new TomSelect(this.element, {
      plugins: this.plugins(),
      allowEmptyOption: true,
      create: this.allowCreateValue,
    })
  }

  plugins(){
    let plugins = ["dropdown_input", "clear_button"]

    if (this.element.multiple) {
      plugins.push("remove_button")
    }

    if (this.hasExtraPluginsValue) {
      plugins = plugins.concat(this.extraPluginsValue)
    }

    return plugins
  }
}
