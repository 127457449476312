import IntervalController from "./interval_controller"

// Este es un intervalo simple. Dura X tiempo y termina.
// Puede tener sonido de metronomo de fondo.
export default class extends IntervalController {

  connect(){
    super.connect();
  }

  playNeededAudio(){
    if (this.isOverDuration()) {
      // sound played in playlist
    } else if (this.currentClick === 0 && this.playlistOutlet.hasAudio("start")) {
      this.playlistOutlet.playAudio("start")
    } else if (this.playClickValue) {
      this.playlistOutlet.playAudio("click")
    }
  }

  renderElapsedTime() {
    super.renderElapsedTime();
    let humanClick = this.currentClick + 1
    let message = `${Math.floor(humanClick / 60)}:${(humanClick % 60).toString().padStart(2, '0')}`
    this.playlistOutlet.clickMessageTarget.innerText = message
  }

}
