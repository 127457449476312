import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'
import _ from "underscore"

export default class extends Controller {

  static targets = ["input", "loadingMessage"]

  initialize(){
    this.log("init invitable component controller")
    this.find = _.debounce(this.find.bind(this), 300)
  }

  find() {
    this.showLoading()
    this.log("finding invitee...")
    get("/teacher_panel/invitable/suggest", {
      query: {
        object_name: this.data.get("objectName"),
        q: this.inputTarget.value
      },
      responseKind: "turbo-stream"
    })
  }

  chooseUser(event) {
    event.preventDefault()
    get("/teacher_panel/invitable/choose_user", {
      query: {
        object_name: this.data.get("objectName"),
        user_id: event.target.dataset.userId
      },
      responseKind: "turbo-stream"
    })
  }

  setEmail(event){
    event.preventDefault()
    get("/teacher_panel/invitable/set_email", {
      query: {
        object_name: this.data.get("objectName"),
        email: event.target.dataset.email
      },
      responseKind: "turbo-stream"
    })
  }

  setPadmaId(event){
    event.preventDefault()
    get("/teacher_panel/invitable/set_padma_id", {
      query: {
        object_name: this.data.get("objectName"),
        email: event.target.dataset.email,
        padma_contact_id: event.target.dataset.padmaContactId
      },
      responseKind: "turbo-stream"
    })
  }

  showLoading(){
    this.loadingMessageTarget.classList.remove("d-none")
  }

  hideLoading(){
    this.loadingMessageTarget.classList.add("d-none")
  }

  log(message){
    console.log(`[${this.identifier}]` + message)
  }

}
