import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"
import { VariousHelpers } from "./concerns/various_helpers"

export default class extends Controller {

  static targets = ["link"]

  initialize() {
    Loggable(this, { debug: true })
    VariousHelpers(this)
  }

  connect() {
    this.log("connecting...")
    if (!navigator.geolocation){
      this.log("Geolocation is not supported by your browser")
    } else {
      this.log("Locating…")
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.log("Latitude: " + position.coords.latitude + " Longitude: " + position.coords.longitude)
          this.linkTargets.forEach((link) => {
            this.addCoordinatesToLink(link, position)
          })
        },
        (error) => {
          this.log("location denied")
        }
      )
    }
    this.log("connected")
  }

  // adds coordinates to the link's href
  addCoordinatesToLink(link, position) {
    const params = { coordinates: { latitude: position.coords.latitude, longitude: position.coords.longitude } }
    this.log("adding", params, "to", link.href)
    link.href = this.addQueryParams(link.href, params)
  }

}
