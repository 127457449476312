import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "form", "submitButton", "filterField" ]

  initialize(){
    this.initChosen()
  }

  connect(){
    this.data.set("active", this.isActive())
  }

  submitForm(){
    this.formTarget.submit()
  }

  // true si hay algún filtro activo
  isActive(){
    this.log("isActive")
    return this.filterFieldTargets.some(field => {
      return this.fieldIsActive(field)
    })
  }

  fieldIsActive(field){
    if (field.type === "checkbox") {
      return field.checked
    } else {
      return field.value.length > 0
    }
  }

  initChosen(){
    $(".chosen-select").chosen()
  }

  log(msg){
    //console.log("[filters] " + msg)
  }

}
