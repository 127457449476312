import Rails from "@rails/ujs"
import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"

// usage:
// <form data-controller="autosubmit" data-autosubmit-target="form">
//   <input type="text" name="query" data-action="autosubmit#submitForm" />
export default class extends Controller {

  static targets = [ "form" ]

  static values = {
    confirmation: Boolean,
    confirmationMessage: String
  }

  initialize(){
    Loggable(this, {debug: false})
  }

  connect(){
    this.log("connected")
    if(this.formIsRemote()){
      $(this.formTarget).on("ajax:success",(e)=>{
        alert("ok");
      })
    }
  }

  submitForm(event){
    if(this.confirmationValue){
      if(confirm(this.confirmationMessageValue)){
        this._submitForm()
      }
    } else {
      this._submitForm()
    }
  }

  _submitForm(){
    this.log("submitting form")
    if(this.formIsRemote()){
      Rails.fire(this.formTarget, 'submit');
    } else {
      this.formTarget.submit()
    }
  }

  formIsRemote(){
    return this.formTarget.dataset.remote=="true";
  }
}
