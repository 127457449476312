// agrega el método <tt>timestampToSeconds</tt> y <tt>isTimestamp</tt> al controlador.
export const Timestampable = (controller) => {

  const isTimestamp = (string) => {
    return string.match(/^\d{1,2}:\d{1,2}:\d{1,2}$/) || string.match(/^\d{1,2}:\d{1,2}$/) || string.match(/^\d{1,2}$/);
  }

  // convert 00:00:00 timestamp to seconds
  const timestampToSeconds = (timestamp) => {
    const parts = timestamp.split(':').map(part => parseInt(part));
    let seconds = 0;

    if (parts.length === 3) {
      // hours, minutes, and seconds
      seconds += parts[0] * 3600; // hours to seconds
      seconds += parts[1] * 60;   // minutes to seconds
      seconds += parts[2];        // seconds
    } else if (parts.length === 2) {
      // minutes and seconds
      seconds += parts[0] * 60;   // minutes to seconds
      seconds += parts[1];        // seconds
    } else if (parts.length === 1) {
      // only seconds
      seconds += parts[0];        // seconds
    }

    return seconds;
  }

  const secondsToTimestamp = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    /* ensure 2 digit format */
    const pad = (num) => num.toString().padStart(2, '0');

    return `${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`;
  }

  Object.assign(controller, { timestampToSeconds, isTimestamp, secondsToTimestamp });
}
