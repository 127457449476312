import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"

export default class extends Controller {
  connect() {
    Loggable(this, { debug: true })
    this.log("connected")
  }

  scrollToTop(event){
    event.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  toggleScrollTop() {
    window.scrollY > 100 ? this.element.classList.add('active') : this.element.classList.remove('active');
  }
}
