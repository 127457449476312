import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"
import { SafeTimeoutable } from "./concerns/safe_timeoutable";
import { CsrfTokenable } from "./concerns/csrf-tokenable";

export default class extends Controller {
  static targets = [ ]

  // flag de que estoy ejecutando un checkin en este momento
  checkingIn = false

  initialize(){
    Loggable(this, {debug: false})
    SafeTimeoutable(this)
    CsrfTokenable(this)
    this.log("initialized")
  }

  connect(){
    this.log("connecting")
  }

  checkinAndRedirect(){
    this.checkingIn = true
    this.log("doin checkin")

    this.doCheckin().then(response => {
      Turbo.visit(response.url)
    }).catch(function(err) {
      this.log(err + " url: " + url)
    });
  }

  silentCheckin(){
    this.checkingIn = true
    this.log("doin silent checkin")

    this.doCheckin().then(response => {
      this.data.set("checkedIn","true")
    }).catch(function(err) {
      this.log(err + " url: " + url)
    });
  }

  doCheckin(){
    return fetch(this.data.get("checkinUrl"), {
      method: 'POST',
      headers: {
        'X-CSRF-Token': this.getCsrfToken(),
      },
      redirect: 'follow'
    })
  }

  checkedIn(){
    return (this.data.get("checkedIn")=="true")
  }

  userIsAuthor(){
    return (this.data.get("userIsAuthor")=="true")
  }

  shouldCheckin(event){
    return !this.checkingIn && !this.checkedIn() && !this.userIsAuthor()
  }

}
