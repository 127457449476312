import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"
import { ShowHide } from "./concerns/showHide";
import { get, patch } from '@rails/request.js'
import _ from "underscore"

export default class extends Controller {

  static targets = [
    "name",
    "allFieldsContainer", "newFieldsContainer", "intervalName",
    "searchResults", "searchingMessage", "pickedAsana"]

  initialize() {
    Loggable(this, { debug: false })
    ShowHide(this)

    this.searchAsanas = _.debounce(this.searchAsanas.bind(this), 300)
  }
  connect() {
    this.log("connected")
  }

  copyData() {
    if (!this.isHidden(this.allFieldsContainerTarget)) {
      if (this.intervalNameTarget.value != "" && this.intervalNameTarget != this.nameTarget.value){
        this.nameTarget.value = this.intervalNameTarget.value
        this.searchAsanas()
      }
    }
  }

  pick(event) {
    this.log("picked asana")
    this.searchResultsTarget.innerHTML = ""
    // tengo event.params.pickedId
    patch(`/timer/asanas/${event.params.pickedId}/pick`, {
      query: {
        interval_id: this.nameTarget.value
      },
      responseKind: "turbo-stream"
    })

    this.nameTarget.value = ""
    this.hide(this.allFieldsContainerTarget)
  }

  // mostrar form para crear un nuevo
  ignoreResults(event) {
    this.searchResultsTarget.innerHTML = ""
    this.show(this.newFieldsContainerTarget)
  }

  clearPick(event) {
    this.pickedAsanaTarget.innerHTML = ""
    this.show(this.allFieldsContainerTarget)
  }

  searchAsanas() {
    this.log("searching asanas...")
    this.hide(this.newFieldsContainerTarget)
    this.show(this.searchingMessageTarget)
    get("/timer/asanas", {
      query: {
        q: this.nameTarget.value
      },
      responseKind: "turbo-stream"
    })
  }

}
