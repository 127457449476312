import { Controller } from "@hotwired/stimulus"
import Holder from "holderjs";

export default class extends Controller {
  static targets = ["entries", "pagination","scrollDownIndicator"]

  scroll(){
    if ( this.scrolledToBottom() ) {
      let next_page = this.paginationTarget.querySelector("a[rel='next']")
      if ( next_page == null ) {
        if(this.data.get("loadInProgress")!="true"){
          this.hidePagination()
        }
        return
      }

      let url = next_page.href

      this.loadMore(url)
    }
  }

  loadMore(url){
    if(this.data.get("loadInProgress")=="true"){
      return
    }
    this.data.set("loadInProgress","true")
    this.showLoadingIndicator()
    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      complete: () => {
        this.data.set("loadInProgress","false")
        return true
      },
      success: (data) => {
        this.entriesTarget.insertAdjacentHTML('beforeend', data.entries)
        this.paginationTarget.innerHTML = data.pagination
        Holder.run()
        return true
      }
    })
  }

  scrolledToBottom() {
    var body = document.body,
        html = document.documentElement

    var height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)

    var anticipation = $("footer").outerHeight()+285 // 285 es la altura de 1 post

    return (window.pageYOffset >= height - window.innerHeight - anticipation)
  }

  showLoadingIndicator(){
    this.paginationTarget.innerHTML = this.data.get("loadingMessage")
  }

  hidePagination(){
    this.paginationTarget.style="display:none"
    if(this.hasScrollDownIndicatorTarget){
      this.scrollDownIndicatorTarget.style="display:none"
    }
  }

}
