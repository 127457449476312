import { Controller } from "@hotwired/stimulus"

// @see https://www.mercadopago.com.ar/developers/es/guides/online-payments/checkout-api/receiving-payment-by-card
export default class extends Controller {

  static targets = [ "cardNumber", "paymentMethodId", "issuer", "transactionAmount", "installments" ]

  initialize(){
  }

  connect(){
    window.Mercadopago.setPublishableKey(this.data.get("publicKey"));
    window.Mercadopago.getIdentificationTypes();
  }

  // busca el tipo de tarjeta (visa,etc)
  guessPaymentMethod(event) {
    let cardnumber = this.cardNumberTarget.value;
    if (cardnumber.length >= 6) {
      let bin = cardnumber.substring(0,6);
      window.Mercadopago.getPaymentMethod({
        "bin": bin
      }, this.setPaymentMethod.bind(this));
    }
  }

  // inicializa el campo con el tipo de tarjeta
  setPaymentMethod(status, response) {
    if (status == 200) {
      let paymentMethod = response[0];
      this.paymentMethodIdTarget.value = paymentMethod.id;

      this.getIssuers(paymentMethod.id);
    } else {
      this.log(`payment method info error: ${response}`);
      alert(this.data.get("errorMsg"))
    }
  }

  // busca emisor de la tarjeta
  getIssuers(paymentMethodId) {
    window.Mercadopago.getIssuers(
        paymentMethodId,
        this.setIssuers.bind(this)
    );
  }

  // inicializa emisor de la tarjeta en el formulario
  setIssuers(status, response) {
    if (status == 200) {
      let issuerSelect = this.issuerTarget;
      response.forEach( issuer => {
        let opt = document.createElement('option');
        opt.text = issuer.name;
        opt.value = issuer.id;
        issuerSelect.appendChild(opt);
      });

      this.getInstallments(
          this.paymentMethodIdTarget.value,
          this.transactionAmountTarget.value,
          issuerSelect.value
      );
    } else {
      this.log(`issuers method info error: ${response}`);
      alert(this.data.get("errorMsg"))
    }
  }

  // busca cuotas disponibles para la tarjeta en mercadopago
  getInstallments(paymentMethodId, transactionAmount, issuerId){
    window.Mercadopago.getInstallments({
      "payment_method_id": paymentMethodId,
      "amount": parseFloat(transactionAmount),
      "issuer_id": parseInt(issuerId)
    }, this.setInstallments.bind(this));
  }

  // inicializa cuotas disponibles en el formulario
  setInstallments(status, response){
    if (status == 200) {
      this.installmentsTarget.options.length = 0;
      response[0].payer_costs.forEach( payerCost => {
        let opt = document.createElement('option');
        opt.text = payerCost.recommended_message;
        opt.value = payerCost.installments;
        this.installmentsTarget.appendChild(opt);
      });
    } else {
      this.log(`installments method info error: ${response}`);
      alert(this.data.get("errorMsg"))
    }
  }

  getCardToken(event){
    event.preventDefault()
    if(this.data.get("doSubmit")=="false"){
      let $form = this.element
      window.Mercadopago.createToken($form, this.setCardTokenAndPay.bind(this))
      return false
    }
  }

  setCardTokenAndPay(status, response) {
    if (status == 200 || status == 201) {
      let form = this.element;
      let card = document.createElement('input')
      card.setAttribute('name', 'token')
      card.setAttribute('type', 'hidden')
      card.setAttribute('value', response.id)
      form.appendChild(card)
      this.data.set("doSubmit",true)
      form.submit()
    } else {
      this.log("Verify filled data!\n"+JSON.stringify(response, null, 4));
      alert(this.data.get("errorMsg"))
    }
  }

  log(msg){
    console.log("[mp-form] "+msg)
  }
}
