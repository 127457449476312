import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"
import { CsrfTokenable } from "./concerns/csrf-tokenable"
import { Timestampable } from "./concerns/timestampable"

// If initialTimestamp is present, the video will start at that timestamp.
export default class extends Controller {
  static targets = [ 'iframeContainer' ]

  static values = {
    initialTimestamp: String
  }

  static outlets = [ "post-open-session" ]

  // flag de que estoy ejecutando un checkin en este momento
  checkingIn = false

  // contador de cantidad de refresh que se van haciendo
  // timeupdate se dispara cada checkEveryMs (1s)
  timeUpdatesCount = 0
  checkEveryMs = 1000

  // solo hacer checkin después de 1min seguidos de reproducción
  //  si hacen "seeked" no se resetea la cuenta.
  checkinWaitTimeUpdatesCount = 60

  player = null

  initialize() {
    Loggable(this, {debug: false})
    CsrfTokenable(this)
    Timestampable(this)
    this.loadYouTubeSDK()
  }

  connect(){
    this.log("connected, waiting for onYouTubeIframeAPIReady")
  }

  loadYouTubeSDK(){
    var tag = document.createElement("script")
    tag.src = "https://www.youtube.com/iframe_api"
    tag.onload = () => {
      this.log("loaded youtube iframe api")
    }
    this.element.parentNode.insertBefore(tag, this.element)
  }

  onYouTubeIframeAPIReady(){
    this.initPlayer()
  }

  initPlayer(){
    this.log("initPlayer")
    let refreshIntervalId
    this.player = new window.YT.Player(this.iframe(), {
      events: {
        // @see https://developers.google.com/youtube/iframe_api_reference#Events
        onReady: (event) => {
          this.restorePosition()
        },
        onStateChange: (event) => {
          // event.data is -1:unstarted, 0:ended, 1:playing, 2:paused, 3:buffering, 5:video cued.
          if(event.data == 1) {
            refreshIntervalId = setInterval(() => {
              this.timeUpdatesCount++
              let curPosition = this.player.getCurrentTime()
              if (this.hasPostOpenSessionOutlet){
                this.postOpenSessionOutlet.tickUpdateSession(this.timeUpdatesCount , {position: curPosition})
              }
              if(this.shouldSilentCheckin(event)){
                this.silentCheckin()
              }
            }, this.checkEveryMs)
          } else {
            clearInterval(refreshIntervalId)
            if(event.data == 0) {
              if(this.shouldCheckin(event)){
                this.checkinAndRedirect()
              } else {
                if (this.hasPostOpenSessionOutlet){
                  this.postOpenSessionOutlet.destroySession()
                }
              }
            }
          }
        },
        onError: (event) => {
          // event.data is 2:invalid parameter, 100:video not found, 101:video not allowed in embedded players, 150:video not allowed in embedded players
        }
      }
    })
  }

  checkinAndRedirect(){
    this.checkingIn = true
    this.log("doin checkin")

    this.doCheckin().then(response => {
      Turbo.visit(response.url)
    }).catch(function(err) {
      this.log(err + " url: " + url)
    });
  }

  silentCheckin(){
    this.checkingIn = true
    this.log("doin silent checkin")

    this.doCheckin().then(response => {
      this.data.set("checkedIn","true")
    }).catch(function(err) {
      this.log(err + " url: " + url)
    });
  }

  doCheckin(){
    return fetch(this.data.get("checkinUrl"), {
      method: 'POST',
      headers: {
        'X-CSRF-Token': this.getCsrfToken()
      },
      redirect: 'follow'
    })
  }

  checkedIn(){
    return (this.data.get("checkedIn")=="true")
  }

  userIsAuthor(){
    return (this.data.get("userIsAuthor")=="true")
  }

  aboveCheckinPercentageThreashold(event){
    let totalDuration = this.player.getDuration()
    let curPosition = this.player.getCurrentTime()
    let percentage = (curPosition/totalDuration)*100
    return percentage > this.data.get("percentageForAutoCheckin")
  }

  shouldSilentCheckin(event){
    return this.shouldCheckin(event) && this.aboveCheckinPercentageThreashold(event) && (this.timeUpdatesCount > this.checkinWaitTimeUpdatesCount)
  }

  shouldCheckin(event){
    return !this.checkingIn && !this.checkedIn() && !this.userIsAuthor()
  }

  togglePlay(keyEvent){
    this.log("toggle play")
    /* ignore keyEvent if its in a text input or textarea */
    if (keyEvent.target.tagName === "INPUT" || keyEvent.target.tagName === "TEXTAREA") {
      return
    }

    keyEvent.preventDefault()

    if (this.player) {
      if(this.player.getPlayerState() == 1){
        this.player.pauseVideo()
      } else {
        this.player.playVideo()
      }
    }
  }

  restorePosition(){
    if (this.hasInitialTimestampValue && this.isTimestamp(this.initialTimestampValue)){
      this.setPosition(this.timestampToSeconds(this.initialTimestampValue))
    }
  }

  setPosition(seconds){
    this.log("setting position: "+seconds)
    this.player.seekTo(seconds, true)
  }

  iframe(){
    return this.iframeContainerTarget.querySelector("iframe")
  }

}
