import { Controller } from "@hotwired/stimulus"
import jstz from 'jstimezonedetect'
import { Loggable } from "./concerns/loggable"

// @see suggest_tz_change_controller.js
export default class extends Controller {

  static targets = [ "tzField" ]

  initialize(){
    Loggable(this, { debug: false })
    this.log("initialize")
  }

  connect(){
    this.log("connect")
    this.saveTzOnCookie()
    this.saveTzOnTargetFields()
  }

  // Gets browser timezone and saves it to the session cookie
  saveTzOnCookie(){
    this.log("saveTzOnCookie")
    document.cookie = "timezone="+this.findTimeZone();
  }

  // Sets timezone in target fields
  saveTzOnTargetFields(){
    this.log("saveTzOnTargetFields")
    this.tzFieldTargets.forEach((f) => {
      f.value = this.findTimeZone();
    })
  }

  // Gets the timezone ignoring window.Intl which returns names not compatible with Rails
  // @see https://github.com/iansinnott/jstz
  findTimeZone() {
    const oldIntl = window.Intl
    try {
      window.Intl = undefined
      const tz = jstz.determine().name()
      window.Intl = oldIntl
      return tz
    } catch (e) {
      // sometimes (on android) you can't override intl
      return jstz.determine().name()
    }
  }

}
