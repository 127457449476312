import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"
import { Dismissable } from "./concerns/dismissable"
import { ShowHide } from "./concerns/showHide"
import { usePwaHelpers } from "./concerns/use_pwa_helpers";

export default class extends Controller {

  static targets = [ "iosInstructions", "androidInstructions", "appleDesktopInstructions" ]

  initialize() {
    Loggable(this, { debug: false })
    Dismissable(this, {expiration: 30})
    ShowHide(this, { hiddenClassName: "d-none" })
    usePwaHelpers(this)
  }

  connect() {
    this.log("connected")

    if (this.canInstall()) {
      this.log("can install")
      if (this.isInstalled()) {
        this.log("is installed")
        this.hide(this.element)
        this.setInstalledCss()
      } else {
        this.log("is not installed")
        if (this.wasDismissed()) {
          this.log("said not now")
          this.hide(this.element)
        } else {
          this.log("did not say not now")
          this.showInstructions()
        }
      }
    } else {
      this.log("cannot install")
      this.hide(this.element)
    }
  }

  showInstructions(){
    this.show(this.element)
    if (this.isIos()){
      this.show(this.iosInstructionsTarget);
    } else if (this.isAndroidPhone()) {
      this.show(this.androidInstructionsTarget);
    } else if (this.isAppleDesktop()) {
      this.show(this.appleDesktopInstructionsTarget);
    } else {
      this.log("unknown device")
      this.hide(this.element)
    }
  }

  setInstalledCss(){
    var r = document.querySelector(':root');
    r.style.setProperty('--tap-bar-height', '100px');
    r.style.setProperty('--tap-bar-padding-top', '5px');
  }
}
