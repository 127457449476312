import { Controller } from "@hotwired/stimulus"
import Holder from "holderjs"

export default class extends Controller {
  static targets = ["agendaContainer", "dateContainer", "loadingMessageContainer"]

  connect(){
    if ( this.scrolledToBottom() ) {
      this.loadMore(this.nextPageUrl())
    }
  }

  scroll(){
    if ( this.scrolledToBottom() ) {
      this.loadMore(this.nextPageUrl())
    }
  }

  loadMore(url){
    if(this.data.get("loadInProgress")=="true"){
      return
    }
    this.data.set("loadInProgress","true")
    this.showLoadingIndicator()
    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      complete: () => {
        this.data.set("loadInProgress","false")
        this.hideLoadingIndicator()
        return true
      },
      success: (data) => {
        this.agendaContainerTarget.insertAdjacentHTML('beforeend', data.entries)
        Holder.run()
        return true
      }
    })
  }

  scrolledToBottom() {
    var body = document.body,
        html = document.documentElement

    var height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)

    var anticipation = $("footer").outerHeight()+200

    return (window.pageYOffset >= height - window.innerHeight - anticipation)
  }

  showLoadingIndicator(){
    this.loadingMessageContainerTarget.innerHTML = this.data.get("loadingMessage")
  }

  hideLoadingIndicator(){
    this.loadingMessageContainerTarget.innerHTML = ""
  }

  lastRenderedDate(){
    return this.dateContainerTargets[this.dateContainerTargets.length-1].dataset.date
  }

  nextDate(){
    let lastDate = new Date(this.lastRenderedDate())
    let nextDate = new Date(lastDate.setDate(lastDate.getDate()+1))
    return nextDate.toISOString().split("T")[0]
  }

  nextPageUrl(){
    let params = new URLSearchParams(document.location.search)
    params.set("onlyDate",this.nextDate())

    return document.location.origin + document.location.pathname + "?" + params.toString()
  }

  log(msg){
    console.log("[schedule-infinite-scroll] "+msg)
  }

}
