import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"

// Google Analytics Tagging
//
// ATENCION: Los eventos registrados se envian a los GoogleAnalytics de todas las escuelas que lo tengan configurado también.
//
// @example:
//   <button data-controller="gtag"
//           data-action="gtag#pushEvent"
//           data-gtag-event-name-value="click"
//           data-gtag-event-params-value='{"event_category": "button", "event_label": "click"}'
//   >Click Me</button>
export default class extends Controller {

  static values = {
    eventName: String,
    eventParams: Object
  }

  connect() {
    Loggable(this, { debug: false })
    this.log("connected")
  }

  pushEvent() {
    this.log("pushEvent", this.eventNameValue, this.eventParamsValue)
    gtag("event", this.eventNameValue, this.eventParamsValue)
  }

}
