import { Controller } from "@hotwired/stimulus"
import jstz from 'jstimezonedetect'
import { Loggable } from "./concerns/loggable"
import { ShowHide } from "./concerns/showHide"
import { Dismissable } from "./concerns/dismissable"

// @see save_timezone_controller.js
export default class extends Controller {

  static values = {
    userTzHoursOffset: Number,
    userTzName: String,
    updateMessage: String
  }

  static targets = [ "browserTzName", "updateButton" ]

  initialize(){
    Loggable(this, { debug: false })
    ShowHide(this)
    Dismissable(this)
    this.log("initialize")
  }

  connect(){
    if (this.shouldSuggestChange() && !this.wasDismissed()){
      this.setTzName()
      this.updateButtonText()
      this.show(this.element)
    }
  }

  updateButtonText(){
    this.updateButtonTarget.value = `${this.updateMessageValue} ${this.browserTzNameTarget.value}`
  }

  setTzName(){
    this.browserTzNameTarget.value = this.findTzName()
  }

  shouldSuggestChange(){
    this.log("browser tz offset", this.findTzHoursOffset())
    this.log("backend tz offset", this.userTzHoursOffsetValue)
    return this.findTzHoursOffset() !== this.userTzHoursOffsetValue
  }

  // Offset in hours
  findTzHoursOffset() {
    try {
      // Ni detecta el timezone, directo mira offset en la hora actual del browser
      // multiplica por -1 para que sea compatible con Rails
      // @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getTimezoneOffset
      return (new Date().getTimezoneOffset()/60) * -1
    } catch (e) {
      // Gets the timezone ignoring window.Intl which returns names not compatible with Rails
      // @see https://github.com/iansinnott/jstz
      const oldIntl = window.Intl
      try {
        window.Intl = undefined
        const tz = jstz.determine().offsets[0]/60
        window.Intl = oldIntl
        return tz
      } catch (e) {
        // sometimes (on android) you can't override intl
        return jstz.determine().offsets[0]/60
      }
    }
  }

  // Gets the timezone ignoring window.Intl which returns names not compatible with Rails
  // @see https://github.com/iansinnott/jstz
  findTzName() {
    const oldIntl = window.Intl
    try {
      window.Intl = undefined
      const tz = jstz.determine().name()
      window.Intl = oldIntl
      return tz
    } catch (e) {
      // sometimes (on android) you can't override intl
      return jstz.determine().name()
    }
  }

}
