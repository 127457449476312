import { Controller } from "@hotwired/stimulus"
import PullToRefresh from "pulltorefreshjs"

/*
  * Add data-controller="pull-to-refresh" to the element you want to be pulled on.
 */
export default class extends Controller {
  connect() {
    PullToRefresh.init({
      mainElement: this.element,
      onRefresh() {
        Turbo.visit(window.location.href, { action: "replace" })
      }
    });
  }
}
