import { Controller } from "@hotwired/stimulus"
import { ShowHide } from "./concerns/showHide"
import { Loggable } from "./concerns/loggable"

export default class extends Controller {

  static targets = ["file", "submit"]

  initialize() {
    Loggable(this, { debug: true })
    ShowHide(this)
  }

  connect() {
    this.toggleButton()
    this.log("connected")
  }

  toggleButton(event) {
    this.log("toggleButton")
    this.log(this.fileTarget.files)
    if (this.fileTarget.files.length > 0) {
      this.show(this.submitTarget)
    } else {
      this.hide(this.submitTarget)
    }
  }

}
