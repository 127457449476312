import { Controller } from "@hotwired/stimulus"
import Holder from "holderjs"

export default class extends Controller {

  connect(){
    Holder.run()
  }

}
