import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "longitude", "latitude" ]

  connect(){
    this.getLocation()
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.storePosition.bind(this));
    }
  }

  storePosition(position) {
    if (this.hasLongitudeTarget && this.hasLatitudeTarget) {
      this.longitudeTarget.value = position.coords.longitude;
      this.latitudeTarget.value = position.coords.latitude;
    }
  }

}
