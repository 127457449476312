import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"

export default class extends Controller {

  static sigPad = null
  static targets = [ "signature", "dataUsageSignature"]

  initialize(){
    Loggable(this, {debug: false})
  }
  connect(){
    this.initBootstrapValidation()
  }

  initBootstrapValidation(){
    window.addEventListener('load', () => {
      // Fetch all the forms we want to apply custom Bootstrap validation styles to
      let forms = document.getElementsByClassName('needs-validation');
      // Loop over them and prevent submission
      let validation = Array.prototype.filter.call(forms, function(form) {
        form.addEventListener('submit', function(event) {
          if (form.dataset.isValid === "true") {
            // is valid, do nothing
          } else {
            event.preventDefault();
            event.stopPropagation();
          }
          form.classList.add('was-validated');
        }, false);
      });
    }, false);
  }

  submitIfValid(event){
    this.log("submitIfValid")
    if (this.validate()){
      this.log("submitIfValid: valid")
      this.element.submit() // si uso requestSubmit() en lugar de submit() no se ponen los estilos de validacion de bootstrap
    }
  }

  validate(){
    if (this.element.checkValidity() === true && this.validateSignature()) {
      this.element.dataset.isValid = "true"
    } else {
      this.element.dataset.isValid = "false"
    }
    return this.isValid()
  }
  isValid(){
    return this.element.dataset.isValid === "true"
  }

  isSigned(){
    return (this.signatureTarget.dataset.signaturePadEmpty == "false") && (this.dataUsageSignatureTarget.dataset.signaturePadEmpty == "false")
  }

  validateSignature(event){
    if( !this.isSigned() ){
      alert(this.data.get("signature-missing-msg"))
      event.preventDefault()
      return false
    } else {
      return true
    }
  }

}
