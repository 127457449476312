import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"

export default class extends Controller {

  static values = {
    isExpanded: Boolean
  }
  static targets = [ "sidebar", "body", "contractButton", "expandButton" ]

  initialize() {
    Loggable(this, { debug: false })
  }

  connect() {
  }

  toggle() {
    this.toggleButton()
    if (this.isExpandedValue) {
      this.contract()
    } else {
      this.expand()
    }
  }

  contract() {
    this.log("showing sidebar...")

    this.sidebarTarget.classList.remove("expand-video--hideSidebarWithAnimation")
    this.bodyTarget.classList.replace("col", "col-md-7")
    this.isExpandedValue = false
  }

  expand() {
    this.log("hiding sidebar...")

    // el d-none cambia el display y la transition se ve rara xq no es un cambio de width.
    // de esta manera se ve bien la transicion al expandir.
    this.sidebarTarget.classList.add("expand-video--hideSidebarWithAnimation")
    this.bodyTarget.classList.replace("col-md-7", "col")
    this.isExpandedValue = true
  }

  toggleButton(){
    this.contractButtonTarget.classList.toggle("d-none")
    this.expandButtonTarget.classList.toggle("d-none")
  }
}
