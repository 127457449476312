import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"
import { Dismissable } from "./concerns/dismissable"
import { ShowHide } from "./concerns/showHide"

// Connects to data-controller="web-push-subscribe"
// @see Notification.permission : https://developer.mozilla.org/en-US/docs/Web/API/Notification/permission_static
export default class extends Controller {

  static targets = ["enableButton", "successMessage", "failureMessage"]

  initialize() {
    Loggable(this, {debug: false})
    Dismissable(this, {})
    ShowHide(this, { hiddenClassName: "d-none" })
  }

  connect() {
    this.log("connected")
    if (this.wasDismissed()) {
      this.log("user dismissed web push subscription")
      return
    }

    // register service worker first. Safari requires serviceWorker to be registered before asking for permission
    this.registerServiceWorker()

    this.showEnableButton()
  }

  showEnableButton(){
    if (!navigator.serviceWorker || !window.PushManager || !Notification) {
      this.log("notifications not supported by this browser")
      this.hide(this.element)
    } else if (Notification.permission !== "default") {
      this.log("notifications already granted or denied")
      this.hide(this.element)
    } else {
      this.log("notifications neither granted nor denied")
      // me ha pasado que me muestre el botón aunque ya autorizó.
      // por eso, chequeo si ya está suscripto
      this.checkCurrentSubscription().then(subscribed => {
        if (subscribed) {
          this.log("but subscription is already registered")
          this.hide(this.element)
        } else {
          this.log("showing enable button")
          this.show(this.enableButtonTarget)
        }
      })
    }
  }

  registerServiceWorker(){
    if (!navigator.serviceWorker) {
      this.log("service worker not supported")
      return
    }

    navigator.serviceWorker.getRegistration().then(registration => {
      if (registration) {
        this.log("service worker already registered", registration)
      } else {
        this.log("registering service worker")
        navigator.serviceWorker.register('/service-worker.js')
      }
    })
  }

  requestPermission(event){
    this.log("prompting for permission")
    event.stopPropagation()
    event.preventDefault()
    this.disable(event.target)

    // extract key and path from this element's attributes
    const key = Uint8Array.from(atob(this.element.dataset.key),
      m => m.codePointAt(0))

    // request permission, perform subscribe, and post to server
    Notification.requestPermission().then(permission => {
      if (Notification.permission === "granted") {
        this.log("permission granted")
        navigator.serviceWorker.getRegistration()
          .then(registration => {
            return registration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: key
            })
          })
          .then(subscription => {
            subscription = subscription.toJSON()
            let formData = new FormData(this.element.querySelector('form'))
            formData.set('web_push_subscription[endpoint]', subscription.endpoint)
            formData.set('web_push_subscription[auth_key]', subscription.keys.auth)
            formData.set('web_push_subscription[p256dh_key]', subscription.keys.p256dh)

            return fetch(this.element.dataset.path, {
              method: 'POST',
              headers: {'Content-Type': 'application/x-www-form-urlencoded'},
              body: new URLSearchParams(formData).toString()
            })
          }).then(response => {
          if (response.ok) {
            this.log("WebPushSubscription saved")
            this.hide(this.enableButtonTarget)
            this.show(this.successMessageTarget)
          } else {
            this.log("WebPushSubscription failed")
            this.disable(submit)
            this.hide(this.enableButtonTarget)
            this.show(this.failureMessageTarget)
          }
        })
          .catch(error => {
            this.error(`Web Push subscription failed: ${error}`)
          })
      } else {
        this.log("permission denied")
        this.hide(this.enableButtonTarget)
        this.show(this.failureMessageTarget)
      }
    })
  }

  // se fija si hay una suscripción en el navegador Y está guardada en el servidor
  // retorna promise<boolean>
  checkCurrentSubscription(){
    return this.getPushSubscription().then(subscription => {
      if (subscription) {
        this.log("subscribed on client", subscription)
        return fetch(`${this.element.dataset.path}/show_by_endpoint?endpoint=${subscription.endpoint}`).then(response => {
          if (response.ok) {
            this.log("subscribed on client and server")
            return true
          } else {
            this.log("subscribed on client but not server")
            this.cancelCurrentSubscription()
            return false
          }
        })
      } else {
        this.log("not subscribed on client")
        return false
      }
    })
  }

  // returns a promise that resolves to the subscription
  // @see https://developer.mozilla.org/en-US/docs/Web/API/PushManager/getSubscription
  async getPushSubscription(){
    return navigator.serviceWorker.getRegistration().then(r => { return r.pushManager.getSubscription() })
  }

  cancelCurrentSubscription(){
    return this.getPushSubscription().then(subscription => {
      if (subscription) {
        this.log("cancelling current subscription")
        let formData = new FormData()
        formData.append("endpoint", subscription.endpoint)
        return fetch(`${this.element.dataset.path}/destroy_by_endpoint`, {
          method: 'DELETE',
          headers: {'Content-Type': 'application/x-www-form-urlencoded'},
          body: new URLSearchParams(formData).toString()
        })
        .then(response => {
          if (response.ok) {
            this.log("unsubscribed")
            subscription.unsubscribe()
            return true
          } else {
            this.error("failed unsubscribe", response)
            return false
          }
        })
      }
    })
  }

  // disable the submit button
  disable(submit) {
    submit.removeAttribute('href')
    submit.style.cursor = 'not-allowed'
    submit.style.opacity = '30%'
  }
}
