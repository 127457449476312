import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"

export default class extends Controller {

  static values = {
    progress: {type: Number, default: 0}
  }

  static targets = ["bar"]

  initialize() {
    Loggable(this, { debug: false })
  }

  connect() {
    this.updateProgressBar();
  }

  updateProgressBar() {
    const progress = this.progressValue;
    this.barTarget.style.width = `${progress}%`;
  }

}
