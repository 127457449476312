import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"
import * as bootstrap from "bootstrap"

// https://getbootstrap.com/docs/5.3/components/carousel/#via-javascript
export default class extends Controller {

  static targets = [ "previous", "next" ]

  static values = {
    interval: {type: Number, default: 300},
    touch: {type: Boolean, default: true},
    pause: {type: String, default: "false"},
    ride: {type: Boolean, default: true}
  }

  initialize() {
    Loggable(this, {debug: false})
  }

  connect() {
    this.log("interval: ", this.intervalValue)
    this.log("touch: ", this.touchValue)
    this.carousel = new bootstrap.Carousel(this.element, {
      interval: this.intervalValue,
      touch: this.touchValue,
      pause: this.pauseValue,
      ride: this.rideValue
    })
    this.log("connected")
  }

  next(e){
    this.log("next")
    e.preventDefault()
    this.carousel.next()
  }

  previous(e){
    this.log("previous")
    e.preventDefault()
    this.carousel.prev()
  }
}
