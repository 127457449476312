import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"

export default class extends Controller {

  connect() {
    Loggable(this, { debug: false })
    this.log("Connected!")

    if (this.unreadCount() > 0) {
      this.setBadge(this.unreadCount())
    } else {
      this.clearBadge()
    }
  }

  unreadCount() {
    return this.data.get("unreadCount")
  }

  async setBadge(count) {
    if ('setAppBadge' in navigator) {
      try {
        this.log(`Setting app badge to ${count}`)
        await navigator.setAppBadge(count);
      } catch (error) {
        console.error('Failed to set app badge:', error);
      }
    }
  }

  async clearBadge() {
    if ('clearAppBadge' in navigator) {
      try {
        this.log("Clearing app badge")
        await navigator.clearAppBadge();
      } catch (error) {
        console.error('Failed to clear app badge:', error);
      }
    }
  }
}
