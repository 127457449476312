import Rails from "@rails/ujs"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "dropdown", "salegateway" ]

  initialize(){
  }

  connect(){
    this.showSelectedGateway()
  }

  showSelectedGateway(){
    var selectedPriceRegion = this.dropdownTarget.value
    this.salegatewayTargets.forEach((e) => {
      if (e.dataset.priceRegion == selectedPriceRegion) {
        e.style.display = "block";
      } else {
        e.style.display = "none";
      }
    })
  }

}
