import Appsignal from "@appsignal/javascript";

// https://docs.appsignal.com/front-end/plugins/plugin-window-events.html
import { plugin } from "@appsignal/plugin-window-events";

export const appsignal = new Appsignal({
  key: "11d93d40-925d-4a25-9c74-d67caf2a9ccd",
});

appsignal.use(plugin({}))
