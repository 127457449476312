import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"
import { ShowHide }   from "./concerns/showHide";
import data from "@emoji-mart/data"
import { init } from "emoji-mart"

export default class extends Controller {

  static targets = ["detail"]

  static values = {
    type: String,
    id: String,
  }

  static outlets = ["emoji-picker"]

  connect() {
    Loggable(this, {debug: false})
    ShowHide(this)
    init({ data }) // init emojis
    this.log("connected")
  }

  toggleDetail(event){
    this.log("toggleDetail")
    event.preventDefault()
    this.toggle(this.detailTarget)
  }

  showEmojiPicker(event){
    this.log("showEmojiPicker")
    event.preventDefault()
    this.emojiPickerOutlet.showPicker(event, this.typeValue, this.idValue)
  }

}
