export const VariousHelpers = (controller, options = {}) => {

  const capitalize = (string) => {
    return string[0].toUpperCase() + string.slice(1);
  }

  const isTouchEnabled = () => {
    return ( 'ontouchstart' in window ) || ( navigator.maxTouchPoints > 0 ) || ( navigator.msMaxTouchPoints > 0 );
  }

  // para agregar parámetros a una URL
  const addQueryParams = (url, params) => {
    // Create a URL object
    let urlObj = new URL(url);

    // Get the current search parameters
    let searchParams = new URLSearchParams(urlObj.search);

    // Add new parameters to the search parameters
    for (let key in params) {
      if (params.hasOwnProperty(key)) {
        if (typeof params[key] === 'object') {
          for (let subkey in params[key]) {
            if (params[key].hasOwnProperty(subkey)) {
              searchParams.set(`${key}[${subkey}]`, params[key][subkey]);
            }
          }
        } else {
          searchParams.set(key, params[key]);
        }
      }
    }

    // Set the updated search parameters back to the URL object
    urlObj.search = searchParams.toString();

    // Return the updated URL as a string
    return urlObj.toString();
  }

  // agrego los métodos al controller
  // también agrego las opctiones para que sean accesibles en los métodos
  Object.assign(controller, {
    capitalize,
    isTouchEnabled,
    addQueryParams
  })
}
