import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"
import { post, destroy } from "@rails/request.js"

import data from '@emoji-mart/data'

export default class extends Controller {

  static values = {
    id: String,
    reactableType: String,
    reactableId: String,
    emojiId: String,
  }
  static targets = []

  connect() {
    Loggable(this, {debug: false})
    this.log("connected")
  }

  action(){
    this.log("action")
    if (this.isActive()) {
      this.removeReaction()
    } else {
      this.postReaction()
    }
  }

  isActive(){
    return this.hasIdValue && this.idValue !== ""
  }

  postReaction(){
    this.log("postReaction")
    post("/reactions", {
      body: {
        reactable_type: this.reactableTypeValue,
        reactable_id: this.reactableIdValue,
        reaction: {
          emoji_id: this.emojiIdValue
        }
      },
      responseKind: "turbo-stream"
    })
  }
  removeReaction(){
    this.log("removeReaction")
    destroy(`/reactions/${this.idValue}`, {
      responseKind: "turbo-stream"
    })
  }


}
