import WebPushSubscribeController from "./web_push_subscribe_controller"

export default class extends WebPushSubscribeController {

  static targets = [
    "enableButton", "disableButton",
    "unsupportedMessage",  "deniedMessage",
    "successMessage", "failureMessage"
  ]

  connect(){
    super.connect()
  }

  // se ejecuta en el connect de la clase madre
  showEnableButton(){
    if (!navigator.serviceWorker || !window.PushManager || !Notification) {
      this.log("notifications not supported by this browser")
      this.show(this.unsupportedMessageTarget)
    } else if (Notification.permission === "denied") {
      this.log("notifications denied")
      this.show(this.deniedMessageTarget)
      this.show(this.enableButtonTarget)
    } else {
      this.log("checking subscription")
      this.checkCurrentSubscription().then(subscribed => {
        if (subscribed) {
          this.log("subscribed")
          this.show(this.disableButtonTarget)
        } else {
          this.log("not subscribed")
          this.show(this.enableButtonTarget)
        }
      })
    }
  }

  disablePush(){
    if (this.cancelCurrentSubscription()) {
      this.hide(this.disableButtonTarget)
      this.show(this.successMessageTarget)
    } else {
      this.show(this.failureMessageTarget)
    }
  }

}
