import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"
import PureCounter from "@srexi/purecounterjs"

export default class extends Controller {
  connect() {
    Loggable(this, { debug: true })
    new PureCounter({separator: true});
    this.log("connected")
  }

}
