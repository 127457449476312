import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [  ]

  connect(){
    this.resizeHeader();
  }

  resizeHeader(){
    $("#headerBackground").css({
      'height' : ($(".card-img-top").outerHeight()-20)+"px"
    })
    $(".work-group-info").css({
      'margin-top' : "-"+($("#headerBackground").outerHeight()+12)+'px'
    })
  }

}
