import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"
import { CsrfTokenable } from "./concerns/csrf-tokenable"
import { Timestampable } from "./concerns/timestampable"
// Vimeo included in html layout

// vimeo player doc: https://developer.vimeo.com/player/sdk
export default class extends Controller {
  static targets = [ 'iframeContainer' ]

  static values = {
    initialTimestamp: String
  }

  static outlets = [ "post-open-session" ]

  // flag de que estoy ejecutando un checkin en este momento
  checkingIn = false

  // contador de cantidad de disparos del evento "timeupdate"
  // timeupdate se dispara cada aprox 250ms
  timeUpdatesCount = 0

  // solo hacer checkin después de 2min seguidos de reproducción
  //  si hacen "seeked" no se resetea la cuenta.
  checkinWaitTimeUpdatesCount = (2*60*1000)/250

  initialize(){
    Loggable(this, {debug: true})
    CsrfTokenable(this)
    Timestampable(this)
  }

  connect(){
    this.log("connecting...")

    try { Vimeo } catch (e) {
      this.log("Vimeo is not defined yet")
      setTimeout(() => { this.connect() }, 300)
      return
    }

    this.adjustContainerRatio()
    this.restorePosition()

    this.player().on("ended",() => {
      if(this.shouldCheckin(event)){
        this.checkinAndRedirect()
      } else {
        if (this.hasPostOpenSessionOutlet){
          this.postOpenSessionOutlet.destroySession()
        }
      }
    })
    this.player().on("timeupdate",(event)=>{
      this.timeUpdatesCount++
      if (this.hasPostOpenSessionOutlet){
        this.postOpenSessionOutlet.tickUpdateSession(this.timeUpdatesCount , {position: event.seconds})
      }
      if(this.shouldSilentCheckin(event)){
        this.silentCheckin()
      }
    })
    this.log("connected")
  }

  checkinAndRedirect(){
    this.checkingIn = true
    this.log("doin checkin")

    this.doCheckin().then(response => {
      Turbo.visit(response.url)
    }).catch(function(err) {
      this.error(err + " url: " + url)
    });
  }

  silentCheckin(){
    this.checkingIn = true
    this.log("doin silent checkin")

    this.doCheckin().then(response => {
      this.data.set("checkedIn","true")
    }).catch(function(err) {
      this.log(err + " url: " + url)
    });
  }

  doCheckin(){
    return fetch(this.data.get("checkinUrl"), {
      method: 'POST',
      headers: {
        'X-CSRF-Token': this.getCsrfToken(),
      },
      redirect: 'follow'
    })
  }

  checkedIn(){
    return (this.data.get("checkedIn")=="true")
  }

  userIsAuthor(){
    return (this.data.get("userIsAuthor")=="true")
  }

  aboveCheckinPercentageThreashold(event){
    let per = null;
    if(event.percent === undefined){
      per = event.data.data.percent;
    } else {
      per = event.percent;
    }
    return (per*100)>this.data.get("percentageForAutoCheckin")
  }

  shouldSilentCheckin(event){
    return this.shouldCheckin(event) && this.aboveCheckinPercentageThreashold(event) && (this.timeUpdatesCount > this.checkinWaitTimeUpdatesCount)
  }

  shouldCheckin(event){
    return !this.checkingIn && !this.checkedIn() && !this.userIsAuthor()
  }

  togglePlay(keyEvent){
    this.log("toggle play")
    /* ignore keyEvent if its in a text input or textarea */
    if (keyEvent.target.tagName === "INPUT" || keyEvent.target.tagName === "TEXTAREA") {
      return
    }

    keyEvent.preventDefault()
    this.player().getPaused().then((paused) => {
      if(paused){
        this.player().play()
      } else {
        this.player().pause()
      }
    })
  }

  restorePosition(){
    if (this.hasInitialTimestampValue && this.isTimestamp(this.initialTimestampValue)){
      this.setPosition(this.timestampToSeconds(this.initialTimestampValue))
    }
  }

  setPosition(seconds){
    this.log("setting position: ", seconds)
    this.player().setCurrentTime(seconds).then((seconds)=>{
      // `seconds` indicates the actual time that the player seeks to
    }).catch((error) => {
      this.error(error.name)
      switch (error.name) {
        case 'RangeError':
          // The time is less than 0 or greater than the video's duration
          break;
        default:
          // Some other error occurred
          break;
      }
    })
  }

  iframe(){
    return this.iframeContainerTarget.querySelector("iframe")
  }
  player(){
    return new Vimeo.Player(this.iframe())
  }

  async isVertical(){
    let dimensions = await Promise.all([this.player().getVideoWidth(), this.player().getVideoHeight()])
    let width = dimensions[0];
    let height = dimensions[1];
    return width < height
  }

  // si el video es vertical, ajustar el ratio del contenedor
  async adjustContainerRatio(){
    let isVertical = await this.isVertical()
    if (isVertical){
      this.element.classList.remove("ratio-16x9")
      this.element.classList.add("ratio-1x1")
    }
  }

}
