import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"

export default class extends Controller {

  static targets = ["link"]

  static values = {
    appKey: String,
    width: { type: String, default: "100%" },
    height: { type: String, default: "400px" }
  }

  initialize() {
    Loggable(this, { debug: true })
    this.addScript()
  }

  connect() {
    this.log("connecting...")

    try { Dropbox } catch (e) {
      this.log("Dropbox is not defined yet")
      setTimeout(() => { this.connect() }, 500)
      return
    }

    this.triggerEmbed()
    this.log("connected")
  }

  triggerEmbed(event) {
    const url = this.linkTarget.href
    var options = {
      // Shared link to Dropbox file
      link: url,
      file: {
        // Sets the zoom mode for embedded files. Defaults to 'best'.
        zoom: "best", // or "fit"
      },
      folder: {
        // Sets the view mode for embedded folders. Defaults to 'list'.
        view: "list", // or "grid"
        headerSize: "small" // or "small"
      }
    }
    Dropbox.embed(options, this.element);
    const iframe = this.element.getElementsByTagName("iframe")[0]
    this.log(iframe)
    iframe.style.width = this.widthValue
    iframe.style.height = this.heightValue
  }


  addScript(){
    if (!document.getElementById("dropboxjs")) {
      // <script type="text/javascript" src="https://www.dropbox.com/static/api/2/dropins.js" id="dropboxjs" data-app-key="YOUR_APP_KEY"></script>
      const script = document.createElement("script")
      script.id = "dropboxjs"
      script.src = "https://www.dropbox.com/static/api/2/dropins.js"
      script.setAttribute("data-app-key", this.appKeyValue)
      document.body.appendChild(script)
    }
  }

}
