import { Controller } from "@hotwired/stimulus"
import "select2"

export default class extends Controller {

  initialize(){
  }
  connect(){
    $(this.element).select2({
      allowClear: true, // se puede sobreescribir con data-allow-clear. ej: para multiple select
      minimumInputLength: 3,
      placeholder: 'Search', // se puede sobreescribir con data-placeholder
      ajax: {
        dataType: 'json',
        url: '/admin/users.json?scope=all', // se puede sobreescribir con data-ajax--url, ej: data-ajax--url: admin_posts_url(scope: "all", q: {type_eq: "Video"})
        method: 'GET',
        delay: 400,
        data: function (params) {
          var query = {
            order: "first_name_desc",
            q: {
              select_name_i_cont_all: params.term.split(" ")
            }
          }
          return query;
        },
        processResults: function (data) {
          return {
            results: data.map((i) => {
              return {id: i.id, text: i.select_name}
            }),
            pagination: {
              more: false
            }
          }
        }
      }
    });
  }


}
