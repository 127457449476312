import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "accountSelector", "submitButton" ]

  initialize(){
  }
  connect(){
    this.log("connected")
    this.initSelect2()
    this.toggleButton()
  }

  initSelect2(){
    this.log("init select2")
    $(this.accountSelectorTarget).select2()
    $(this.accountSelectorTarget).on('select2:select', (e) => {
      this.toggleButton()
    });
  }

  toggleButton(event){
    if (this.accountSelectorTarget.value == "") {
      this.submitButtonTarget.disabled = true
    } else {
      this.submitButtonTarget.disabled = false
    }
  }

  redirectToForm(event){
    if (event) {
      event.preventDefault();
    }
    this.log("redirect")
    document.location = "/a/" + this.accountSelectorTarget.value + this.data.get("endpoint")
  }

  log(msg){
    console.log("[form_select_account] "+msg)
  }

}
