import AutocheckinController from "./autocheckin_controller";

export default class extends AutocheckinController {
  static targets = [ ]

  // flag de que estoy ejecutando un checkin en este momento
  checkingIn = false

  connect(){
    super.connect()
    this.safeSetTimeout(
      () => {
        this.log("time!")
        if(this.shouldCheckin()){
          this.log("should checkin")
          this.silentCheckin();
        } else {
          this.log("should not checkin")
        }
      },
      this.data.get("waitSeconds") * 1000
    )
  }

  aboveCheckinPercentageThreashold(event){
    let per = null;
    if(event.percent === undefined){
      per = event.data.data.percent;
    } else {
      per = event.percent;
    }
    return (per*100)>this.data.get("percentageForAutoCheckin")
  }

  shouldSilentCheckin(event){
    return this.shouldCheckin(event) && this.aboveCheckinPercentageThreashold(event) && (this.timeUpdatesCount > this.checkinWaitTimeUpdatesCount)
  }

}
