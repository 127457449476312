import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"
import { ShowHide } from "./concerns/showHide";

/* will hide or show according to scroll position */
export default class extends Controller {

  static values = {
    hiddenClassName: String,
    showWhenYLt: Number,
    showWhenYGt: Number
  }

  initialize() {
    Loggable(this, { debug: false })
    ShowHide(this, {hiddenClassName: this.hiddenClassNameValue})
  }
  connect() {
    this.toggleElement()
    this.log("connected")
  }

  toggleElement() {
    this.log("toggleElement", this.showWhenYLtValue)
    this.shouldShow() ? this.show(this.element) : this.hide(this.element);
  }

  shouldShow() {
    if (this.showWhenYLtValue) {
      return window.scrollY < this.showWhenYLtValue
    } else if (this.showWhenYGtValue) {
      return window.scrollY > this.showWhenYGtValue
    }
  }
}
