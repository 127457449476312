import { Controller } from "@hotwired/stimulus"
import Holder from "holderjs"
import { Loggable } from "./concerns/loggable"

export default class extends Controller {

  initialize(){
  }

  connect(){
    Loggable(this, { debug: false })
    Turbo.setProgressBarDelay(250) /* show loading bar after 250ms (default delay es 500ms) */
  }

  initPageJS(event){
    this.log("initPageJS.")

    this.runHolder(event);
    this.initClipboardCopy(event);
  }

  runHolder(event){
    Holder.run();
  }

  initClipboardCopy(event){
    try { ClipboardJS } catch (e) {
      this.log("ClipboardJS is not defined yet")
      setTimeout(() => { this.initClipboardCopy() }, 300)
      return
    }

    var clipboard = new ClipboardJS('.clipboardCopy');
    clipboard.on('success', function(e) {
      alert(e.trigger.dataset.copiedMsg);
    });
  }
}
