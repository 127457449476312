import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"

export default class extends Controller {

  static values = {
    publicKey: String,
    amount: Number,
    payerEmail: String,

    processingUrl: String,
    resolvedUrl: String,

    submitText: String,

    locale: String
  }

  static targets = ["errorContainer", "errorText", "saveCardCheckbox"]

  connect() {
    Loggable(this, {debug: false})

    const mp = new MercadoPago(this.publicKeyValue, {
      locale: this.localeValue
    });
    const bricksBuilder = mp.bricks();

    this.renderCardPaymentBrick(bricksBuilder);
    this.log("connected")
  }


  async renderCardPaymentBrick(bricksBuilder) {
    // podría inicializar el form con datos del usuario: https://www.mercadopago.com.ar/developers/es/docs/checkout-bricks/card-payment-brick/advanced-features/initialize-data-on-the-bricks
    const settings = {
      initialization: {
        amount: this.amountValue, // monto total a pagar
        payer: {
          email: this.payerEmailValue
        }
      },
      customization: {
        paymentMethods: {
          minInstallments: 1,
          maxInstallments: 1,
        },
        visual: {
          texts: {
            formSubmit: this.submitTextValue,
          }
        }
      },
      callbacks: {
        onReady: () => {
          /*
            Callback llamado cuando Brick está listo.
            Aquí puedes ocultar cargamentos de su sitio, por ejemplo.
          */
        },
        onSubmit: (formData) => {
          formData.format = "json" // force json response

          if (this.hasSaveCardCheckboxTarget && this.saveCardCheckboxTarget.checked) {
            formData.save_card = true
          }

          // callback llamado al hacer clic en el botón enviar datos
          return new Promise((resolve, reject) => {
            this.hideError()
            fetch(this.processingUrlValue, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(formData),
            })
              .then((response) => response.json())
              .then((response) => {
                console.log("backend response:", response)
                if (response.id) {
                  // recibir el resultado del pago
                  resolve();
                   Turbo.visit(this.resolvedUrlValue)
                } else {
                  // hubo un error en el pago
                  this.showError(`Error al procesar el pago: ${response.message}`)
                  reject();
                }
              })
              .catch((error) => {
                // hubo un error inesperado
                console.log("calling reject", error)
                alert("Error al procesar el pago (500)")
                reject();
              });
          });
        },
        onError: (error) => {
          // callback llamado para todos los casos de error de Brick
          console.error(error);
        },
      },
    };
    window.cardPaymentBrickController = await bricksBuilder.create(
      'cardPayment',
      'cardPaymentBrick_container',
      settings,
    );
  };

  hideError() {
    this.errorContainerTarget.classList.remove("show")
  }

  showError(message) {
    this.errorTextTarget.innerHTML = message
    this.errorContainerTarget.classList.add("show")
  }

  disconnect() {
    window.cardPaymentBrickController.unmount()
  }
}
