import {Controller} from "@hotwired/stimulus"
import {Loggable} from "./concerns/loggable"
import * as bootstrap from "bootstrap"

// Usage:
// <button type="button" data-controller="popover" class="btn btn-lg btn-danger" data-bs-toggle="popover" data-bs-title="Popover title" data-bs-content="And here's some amazing content. It's very engaging. Right?">Click to toggle popover</button>
export default class extends Controller {

  initialize() {
    Loggable(this, {debug: false})
  }

  connect() {
    this.popover = new bootstrap.Popover(this.element, {
      html: true,
    })

    // Add click event listener to the document body
    // To close the popover when clicking outside of it
    document.body.addEventListener('click', (event) => {
      this.log("hiding")
      if (event.target != this.element) {
        this.popover.hide()
      }
    })
    this.log("connected")
  }

  show(event) {
    event.preventDefault()
    this.popover.show()
    this.log("show")
  }
}
