import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["qrContainer", "qrToggler"]

  initialize() {
    this.log("initialized")
  }

  connect(){
    this.log("connected")
  }

  toggleQR() {
    if(this.data.get("showingQr") == "true"){
      this.hideQR();
    } else {
      this.showQR();
    }

    this.data.set("showingQr",!(this.data.get("showingQr") == "true"))
  }

  hideQR() {
    this.log("hidding qr")
    this.qrContainerTarget.removeChild(this.qrContainerTarget.lastChild);
    this.qrContainerTarget.removeChild(this.qrContainerTarget.lastChild);
    this.qrContainerTarget.removeAttribute('title');
  }

  showQR() {
    this.log("showing qr")
    var containerStyle = window.getComputedStyle(this.qrContainerTarget);
    var h = parseInt(containerStyle.height);
    var w = parseInt(containerStyle.width);
    new QRCode(this.qrContainerTarget, {
      text: this.data.get("qrUrl"),
      width: w,
      height: h,
      colorDark : "#000000",
      colorLight : "#ffffff",
    })._el;
  }

  log(msg){
    console.log("[passport] "+msg)
  }

}
