import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "locale" ]

  connect(){
  }

  changeLocale(){
    var url = this.URL_add_parameter(window.location.href, 'locale', this.localeTarget.value)
    window.location.href = url;
  }

  URL_add_parameter(url, param, value){
    var hash       = {};
    var parser     = document.createElement('a');

    parser.href    = url;

    var parameters = parser.search.split(/\?|&/);

    for(var i=0; i < parameters.length; i++) {
        if(!parameters[i])
            continue;

        var ary      = parameters[i].split('=');
        hash[ary[0]] = ary[1];
    }

    hash[param] = value;

    var list = [];  
    Object.keys(hash).forEach(function (key) {
        list.push(key + '=' + hash[key]);
    });

    parser.search = '?' + list.join('&');
    return parser.href;
  }
} 
