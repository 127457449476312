import IntervalController from "./interval_controller"

// Este es un intervalo para respiración. Tiene cycles y phases.
//
// Cycle:
//   Phase 0: inhale
//   Phase 1: hold in
//   Phase 2: exhale
//   Phase 3: hold out
// Full cycle: 1 or 2 cycles depending on alternation
//
//
// @see https://blog.paul.cx/post/metronome/
// Usage:
// <div data-controller="metronome"
//      data-metronome-state-value="stopped"
//      data-metronome-bpm-value="60" // (60: 1 second, 120: 0.5 second, etc)
//      >
//   <button data-action="click->metronome#toggle" data-target="metronome.toggleButton">Start</button>
// </div>
//
export default class extends IntervalController {

  static targets = [
    "fullCycles",
    "matra",
    "alternation",
    "phase0", "phase1", "phase2", "phase3",
    "phasesDurations"
  ]
  static values = {
    strInhale: String,
    strHoldIn: String,
    strExhale: String,
    strHoldOut: String,
    strLeft: String,
    strRight: String,
    strPositive: String,
    strNegative: String,
  }

  connect() {
    super.connect()
    this.renderDuration()
  }

  initClicks(){
    super.initClicks()
    this._cycleClick = 0
    this._currentPhase = this.firstPhase()
    this._phaseClick = 0
    this._currentCycle = 0 // ciclos respiratorios (phase0 + phase1 + phase2 + phase3)
    this._currentFullCycle = 0 // ciclos considerando alternación (ej: para nadishodhana 1 fullCycle son 2 cycles respiratorios)

    this.calculateNextPhase() // requires this._currentPhase
    this.calculateNextCycle() // requires this._currentCycle and this._nextActionPhase
  }

  initDurations(){
    this.phasesTargets().forEach((el) => { if (el.value === "") { el.value = 0 } })
    this._phase0Duration = parseInt(this.phase0Target.value) * parseInt(this.matraTarget.value)
    this._phase1Duration = parseInt(this.phase1Target.value) * parseInt(this.matraTarget.value)
    this._phase2Duration = parseInt(this.phase2Target.value) * parseInt(this.matraTarget.value)
    this._phase3Duration = parseInt(this.phase3Target.value) * parseInt(this.matraTarget.value)
    this._cycleDuration = this._phase0Duration + this._phase1Duration + this._phase2Duration + this._phase3Duration
    this._fullCycleDuration = this.calculateFullCycleDuration() // requires this._cycleDuration
    if (this.fullCyclesTarget.value !== "") {
      this._totalDuration = this._fullCycleDuration * parseInt(this.fullCyclesTarget.value)
    }
    document.dispatchEvent(new CustomEvent("interval:durationChanged"))
  }

  renderPhasesDurations(){
    this.phasesDurationsTarget.innerText = `${this._phase0Duration}-${this._phase1Duration}-${this._phase2Duration}-${this._phase3Duration}`
  }

  renderDuration(){
    super.renderDuration()
    this.renderPhasesDurations()
  }
  
  incClick(){
    super.incClick()
    this._cycleClick = Math.floor(this.currentClick % this._cycleDuration)
    this._currentPhase = this.calculateCurrentPhase()
    this._phaseClick = this.calculatePhaseClick() // requires this._cycleClick and this._currentPhase
    this._currentCycle = this.calculateCurrentCycle()
    this._currentFullCycle = Math.floor(this._currentCycle / this.cyclesForFullCycle())

    this.calculateNextPhase() // requires this._currentPhase
    this.calculateNextCycle() // requires this._currentCycle and this._nextActionPhase
  }

  playNeededAudio(){
    if (this.isOverDuration()) {
      // sound played in playlist
    } else if (this.currentClick === 0 && this.playlistOutlet.hasAudio("start")) {
      this.playlistOutlet.playAudio("start")
    } else if (this._phaseClick === this.currentPhaseDuration()-1) {
      this.playlistOutlet.playAudio("phaseChange")
    } else if (this.playClickValue) {
      this.playlistOutlet.playAudio("click")
    }
  }

  render(){
    this.playlistOutlet.clickMessageTarget.innerText = this._phaseClick+1
    super.render()
  }

  // counters
  // currentClick, es el contador de clicks total
  calculateCurrentPhase(){
    if (this._cycleClick < this._phase0Duration) {
      return 0
    } else if (this._cycleClick < this._phase0Duration + this._phase1Duration) {
      return 1
    } else if (this._cycleClick < this._phase0Duration + this._phase1Duration + this._phase2Duration) {
      return 2
    } else if (this._cycleClick < this._phase0Duration + this._phase1Duration + this._phase2Duration + this._phase3Duration) {
      return 3
    }
  }

  firstPhase(){
    let firstPhase = 0
    while (this.phaseDuration(firstPhase) === 0) {
      firstPhase = (firstPhase + 1) % 4
    }
    return firstPhase
  }

  calculateNextPhase(){
    this._nextActionPhase = (this._currentPhase + 1) % 4
    while (this.phaseDuration(this._nextActionPhase) === 0) {
      this._nextActionPhase = (this._nextActionPhase + 1) % 4
    }
  }

  // requires this._currentCycle
  // requires this._nextActionPhase
  calculateNextCycle(){
    this._nextActionCycle = this._currentCycle
    if (this._nextActionPhase === 0) {
      this._nextActionCycle = this._currentCycle + 1
    }
    this._nextActionFullCycle = Math.floor(this._nextActionCycle / this.cyclesForFullCycle())
  }

  calculateCurrentCycle(){
    return Math.floor(this.currentClick / this._cycleDuration)
  }

  phaseDuration(phase){
    switch (phase) {
      case 0:
        return this._phase0Duration
      case 1:
        return this._phase1Duration
      case 2:
        return this._phase2Duration
      case 3:
        return this._phase3Duration
    }
  }

  currentPhaseDuration(){
    return this.phaseDuration(this._currentPhase)
  }

  cyclesForFullCycle(){
    switch(this.alternationTarget.value) {
      case "nadishodhana":
      case "anuloma":
      case "pratiloma":
        return 2
      case "surya":
      case "suryabheda":
      case "chandra":
      case "chandrabheda":
      default:
        return 1
    }
  }

  calculateFullCycleDuration(){
    return this.cyclesForFullCycle() * this._cycleDuration
  }
  
  actionMessageFor(phase, cycle){
    let action = ""
    let side = ""
    let sides = null
    let actions = [this.strInhaleValue, this.strHoldInValue, this.strExhaleValue, this.strHoldOutValue]
    action = actions[phase]
    switch(this.alternationTarget.value) {
      case "nadishodhana":
        sides = {
          0: [this.strLeftValue, "", this.strRightValue, ""],
          1: [this.strRightValue, "", this.strLeftValue, ""],
        }
        side = sides[cycle % 2][phase]
        break
      case "surya":
        sides = [this.strPositiveValue, "", this.strPositiveValue, ""]
        side = sides[phase]
        break
      case "suryabheda":
        sides = [this.strPositiveValue, "", this.strNegativeValue, ""]
        side = sides[phase]
        break
      case "chandra":
        sides = [this.strNegativeValue, "", this.strNegativeValue, ""]
        side = sides[phase]
        break
      case "chandrabheda":
        sides = [this.strNegativeValue, "", this.strPositiveValue, ""]
        side = sides[phase]
        break
      case "anuloma":
        sides = {
          0: ["", "", this.strLeftValue, ""],
          1: ["", "", this.strRightValue, ""],
        }
        side = sides[cycle % 2][phase]
        break
      case "pratiloma":
        sides = {
          0: [this.strLeftValue, "", "", ""],
          1: [this.strRightValue, "", "", ""],
        }
        side = sides[cycle % 2][phase]
        break
    }
    return `${action} ${side}`
  }

  actionMessage(){
    return this.actionMessageFor(this._currentPhase, this._currentCycle)
  }

  firstActionMessage(){
    return this.actionMessageFor(this.firstPhase(), 0)
  }

  hasNextAction(){
    return !(this._nextActionFullCycle === parseInt(this.fullCyclesTarget.value))
  }

  nextActionMessage(){
    return this.actionMessageFor(this._nextActionPhase, this._nextActionCycle)
  }

  // Es el click actual dentro de la phase actual
  calculatePhaseClick(){
    switch (this._currentPhase) {
      case 0:
        return this._cycleClick
      case 1:
        return this._cycleClick - this._phase0Duration
      case 2:
        return this._cycleClick - this._phase0Duration - this._phase1Duration
      case 3:
        return this._cycleClick - this._phase0Duration - this._phase1Duration - this._phase2Duration
    }
  }

  phasesTargets(){
    return [this.phase0Target, this.phase1Target, this.phase2Target, this.phase3Target]
  }

}
