import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"

export default class extends Controller {

  static values = {
    length: Number,
    validChars: Array
  }

  initialize() {
    Loggable(this, { debug: true })
  }

  connect() {
    this.formatCode()
    this.log("connected")
  }

  formatCode() {
    this.log("formatting code")
    let value = this.element.value.replace(/\s+/g, '').toUpperCase();
    // remove invalid characters
    value = value.replace(new RegExp(`[^${this.validCharsValue.join('')}]+`, 'g'), '');
    // add a space every 4 characters
    let formattedValue = value.match(/.{1,4}/g)?.join(' ') || value;
    // limit to the specified length
    formattedValue = formattedValue.slice(0, this.lengthValue)
    this.element.value = formattedValue;
  }

}
