import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"

// Place ID Finder: https://developers.google.com/maps/documentation/javascript/examples/places-placeid-finder
export default class extends Controller {

  static values = {
    zoomWLocation: Number, // Zoom when user grants location on browser
    zoomWoutLocation: Number, // Zoom when user denies location on browser
    zoomOutUntilAtLeastOneMarkerIsVisible: Boolean,
    centerLatitude: Number,
    centerLongitude: Number,
    ignoreGeolocation: Boolean,
    googleMapsKey: String,
    locale: String,
  }

  static targets = ["map", "marker"]

  initialize() {
    Loggable(this, {debug: true})
    this.addJsScriptToHead()
  }

  connect() {
    // initMap no se llama on connect si no
    // que se llama cuando se carga el script de google maps
    // con el event "google-maps-calback" que se dispara en el layout
    this.log("connect")
  }

  initMap(){
    this.log("initMap")
    const map = new google.maps.Map(this.mapTarget, {
      zoom: this.zoomWoutLocationValue,
      mapId: "learn-places-map",
      mapTypeControlOptions: {
        mapTypeIds: []
      },
    });
    this.addAllMarkers(map)
    this.initialCenterMap(map)
    if(this.zoomOutUntilAtLeastOneMarkerIsVisibleValue){
      this.zoomOutUntilAtLeastOneMarkersIsVisible(map)
    }
  }

  zoomOutUntilAtLeastOneMarkersIsVisible(map) {
    let markersContained = 0
    let bounds = map.getBounds()
    if (bounds) {
      this.markerTargets.forEach((marker) => {
        // check if any marker is within the bounds
        // if not, zoomOut and retry until at least one marker is visible
        let markerPosition = this.positionForMarker(marker)
        if (bounds && bounds.contains(markerPosition)) {
          markersContained += 1
        }
      })
      if (markersContained === 0) {
        // no marker is visible, zoom out and retry
        let newZoom = map.getZoom() - 1;
        if (newZoom > 0) {
          map.setZoom(newZoom);
          return this.zoomOutUntilAtLeastOneMarkersIsVisible(map)
        }
      }
    } else {
      // bounds is undefined, retry
      setTimeout(() => {
        this.zoomOutUntilAtLeastOneMarkersIsVisible(map)
      }, 1000)
    }
  }

  initialCenterMap(map) {
    if (this.hasGeolocation()) {
      navigator.geolocation.getCurrentPosition((position) => {
        map.setZoom(this.zoomWLocationValue);

        let mapCenter = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
        map.setCenter(mapCenter);
      }, () => {
        let mapCenter = {
          lat: this.centerLatitudeValue,
          lng: this.centerLongitudeValue
        };
        map.setCenter(mapCenter);
      });
    } else {
      let mapCenter = {
        lat: this.centerLatitudeValue,
        lng: this.centerLongitudeValue
      };
      map.setCenter(mapCenter);
    }
  }

  hasGeolocation() {
    const ret = this.ignoreGeolocationValue === false && navigator.geolocation
    this.log("hasGeolocation", ret)
    return ret
  }

  addAllMarkers(map) {
    this.markerTargets.forEach((marker) => {
      this.addMarker(map, marker)
    })
  }

  positionForMarker(marker){
    return {
      lat: parseFloat(marker.dataset.latitude),
      lng: parseFloat(marker.dataset.longitude)
    }
  }

  addMarker(map, marker) {
    const infowindow = new google.maps.InfoWindow();
    const infowindowContent = marker.getElementsByClassName("info-window-content")[0];
    infowindow.setContent(infowindowContent);

    const pin = new google.maps.marker.PinElement({
      scale: 2,
      glyphColor: "#ffffff",
      borderColor: "#c69c6c",
      background: "#040e85"
    })

    const aMarker = new google.maps.marker.AdvancedMarkerElement({
      map,
      title: marker.dataset.name,
      content: pin.element,
      position: this.positionForMarker(marker)
    });

    aMarker.addListener("click", () => {
      infowindow.open(map, aMarker);
    });

    return aMarker
  }

  addJsScriptToHead() {
    this.log("addJsScriptToHead")
    const script = document.createElement("script")
    script.id  = "google-maps-script"
    script.src = `https://maps.googleapis.com/maps/api/js?key=${this.googleMapsKeyValue}&libraries=places,marker&language=${this.localeValue}&callback=dispatchGoogleMapsEvent`
    script.defer = true
    script.async = true
    document.head.appendChild(script)
  }
}
