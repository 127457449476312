import { Controller } from "@hotwired/stimulus"
// AOS included in mkt.html.erb

// http://michalsnik.github.io/aos/
export default class extends Controller {
  connect() {
    if (AOS === undefined) {
      /* espero un poquito para que se inicialice AOS */
      setTimeout(() => {
        AOS.init()
      }, 200)
    } else {
      AOS.init()
    }
  }
}
