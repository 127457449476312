import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ ]

  initialize(){
    const mp = new MercadoPago(this.data.get("publicKey"))

    // Inicializa el checkout
    mp.checkout({
      preference: {
        id: this.data.get("preferenceId")
      },
      // autoOpen: true,
      render: {
        container: '.cho-container', // Indica dónde se mostrará el botón de pago
        label: this.data.get("buttonLabel"), // Cambia el texto del botón de pago (opcional)
      }
      // theme: {
      //   elementsColor: "#c69c6c",
      //   headerColor: "#040e85"
      // }
    });
  }

}
