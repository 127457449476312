import { Controller } from "@hotwired/stimulus"
import _ from "underscore"

export default class extends Controller {

  static targets = ["loadingMessage"]
  static classes = ["hidden"]

  initialize(){
    this.log("initialize")
    this.debouncedSubmit = _.debounce(this.debouncedSubmit.bind(this), 300)
  }

  submit(){
    this.log("submit")
    this.element.requestSubmit()
  }

  debouncedSubmit(){
    this.log("debouncedSubmit")
    this.showLoadingMessage()
    this.submit()
  }

  showLoadingMessage(){
    if (this.hasLoadingMessageTarget && this.hasHiddenClass){
      this.loadingMessageTarget.classList.remove(this.hiddenClass)
    }
  }

  log(message){
    // console.log(`[${this.identifier}]` + message)
  }
}
