import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "entry" ]

  initialize(){
    this.log("initialized")
  }
  connect(){
    this.log("conected")
    this.hideCounterparts()
  }

  hideCounterparts(){
    this.entryTargets.forEach((e) => {
      if ( e.dataset.counterpartId && e.dataset.postType=="InPersonLive" ) {
        this.log(e.dataset.postId+" has counterpart")
        if(this.counterpartFor(e)){
          this.log("hiding")
          this.movePlayer(this.counterpartFor(e),e)
          this.hideCounterpart(e)
        } else {
          this.log("counterpart not found")
        }
      }
    })
  }

  hideCounterpart(entry){
    this.counterpartFor(entry).remove()
  }

  movePlayer(source_entry,target_entry){
    let sourcePlayer = source_entry.getElementsByClassName("entry--player")[0]
    if (sourcePlayer.innerHTML.trim() === "") {
      sourcePlayer.remove()
    } else {
      let targetPlayer = target_entry.getElementsByClassName("entry--player")[0]
      $(sourcePlayer).insertAfter(targetPlayer)
    }
  }

  counterpartFor(entry){
    return this.entryTargets.filter((e)=>{ return e.dataset.postId==entry.dataset.counterpartId })[0]
  }

  log(msg){
    // console.log("[agenda-hybrids] "+msg)
  }
}
