import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"
import { ShowHide } from "./concerns/showHide"
import { CsrfTokenable } from "./concerns/csrf-tokenable";
import Cropper from 'cropperjs';

// https://fengyuanchen.github.io/cropperjs/v2/guide.html#getting-started
export default class extends Controller {

  static targets = [ "image", "button", "fileField", "form" ]

  static values = { }

  initialize() {
    Loggable(this, {debug: false})
    ShowHide(this)
    CsrfTokenable(this)
  }

  connect() {
    this.log("connected")
    this.initCropper()
  }

  initCropper(){
    this.cropperImage = new Cropper(this.imageTarget, { })
    let selection = this.cropperImage.getCropperSelection()
    selection.aspectRatio = 1
    this.enableSubmit()
  }

  crop() {
    this.log("crop")
    this.disableSubmit()
    this.cropperImage.getCropperSelection().$toCanvas().then((canvas) => {
      canvas.crossOrigin = "anonymous"; // Set crossOrigin attribute
      canvas.toBlob((blob) => {
        const file = new File([blob], "avatar.png", { type: "image/png" })

        // Create a new DataTransfer object to hold the File
        const dataTransfer = new DataTransfer()
        dataTransfer.items.add(file)

        // Assign the FileList to the file input
        this.fileFieldTarget.files = dataTransfer.files

        // submit form
        this.formTarget.requestSubmit()
      })
    })
  }

  enableSubmit(){
    this.show(this.buttonTarget)
  }

  disableSubmit(){
    this.hide(this.buttonTarget)
  }

}
