import { Controller } from "@hotwired/stimulus"
import { Dismissable } from "./concerns/dismissable"
import { Loggable } from "./concerns/loggable"
import * as bootstrap from "bootstrap"

export default class extends Controller {
  static targets = [ "modal" ]

  initialize() {
    Loggable(this, {debug: false})
    Dismissable(this, {
      expiration: 1,
      hideFunction: (element) => {
        this.modal.hide()
      }
    })
  }

  connect(){
    this.popup()
  }

  popup(){
    if(!this.wasDismissed()){
      this.modal = new bootstrap.Modal(this.modalTarget, {
        backdrop: "static",
        keyboard: false,
      })
      this.modal.show()
    }
  }

  dismissSuggestLogin(){
    this.dismiss()
  }
}
