import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"
import { ShowHide } from "./concerns/showHide"
import { Timestampable } from "./concerns/timestampable"

// Timestamps para vimeo_player y youtube_player
// Ejemplo:
//     <a data-controller="video-timestamp"
//        data-video-timestamp-timestamp-value="00:20:03"
//        data-video-timestamp-vimeo-player-outlet="div[data-controller=vimeo-player]"
//        data-video-timestamp-youtube-player-outlet="div[data-controller=youtube-player]"
//        data-action="click->video-timestamp#setPlayerToTimestamp"
//        href="#"
//     >Yôganidrá</a>
export default class extends Controller {

  static values = {
    timestamp: String
  }
  static outlets = [ "vimeo-player", "youtube-player" ]

  initialize() {
    Loggable(this, {debug: false})
    ShowHide(this, { hiddenClassName: "d-none" })
    Timestampable(this)
  }

  connect() {
    this.log("connected")

    this.disableIfNoPlayer()
  }

  disableIfNoPlayer(){
    if (!this.hasLinkedPlayer()){
      this.log("no linked player found")
      if (this.element.classList.contains("video-timestamp")){
        this.element.classList.remove("video-timestamp")
      } else {
        this.hide(this.element)
      }
    }
  }

  hasLinkedPlayer() {
    return this.hasVimeoPlayerOutlet || this.hasYoutubePlayerOutlet
  }

  setPlayerToTimestamp(event) {
    event.preventDefault()
    this.log("setPlayerToTimestamp", this.timestampValue)
    if (this.hasVimeoPlayerOutlet) {
      this.vimeoPlayerOutlet.setPosition(this.timestampToSeconds(this.timestampValue))
    }
    if (this.hasYoutubePlayerOutlet) {
      this.youtubePlayerOutlet.setPosition(this.timestampToSeconds(this.timestampValue))
    }
  }
}
