import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "dateSelector", "submitButton", "waitingFlag" ]

  initialize(){
  }

  connect(){
    this.log("connected")
    this.updateMessage()
  }

  log(msg){
    console.log("[booking-form] "+msg)
  }

  beforeSubmit(){
    this.setFlag(this.onWaitlist())
  }

  onWaitlist(){
    return this.dateSelectorTarget.selectedOptions[0].dataset.waitlist=="true"
  }

  updateMessage(){
    if(this.dateSelectorTarget.selectedOptions[0].disabled){
      this.submitButtonTarget.disabled = true
      this.submitButtonTarget.value = this.submitButtonTarget.dataset.selectMessage
    } else {
      this.submitButtonTarget.disabled = false
      if(this.onWaitlist()){
        this.submitButtonTarget.value = this.submitButtonTarget.dataset.waitlistMessage
      } else {
        this.submitButtonTarget.value = this.submitButtonTarget.dataset.vacancyMessage
      }
    }
  }

  setFlag(val){
    this.waitingFlagTarget.value = val
  }


}
