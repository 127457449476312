import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"

export default class extends Controller {

  static values = { url: String }

  static targets = ["urlInput", "timestampInput", "copyButton", "timestampToggle"]

  initialize() {
    Loggable(this, { debug: true })
  }

  connect() {
    this.refreshUrl()
    this.log("connected")
  }

  refreshUrl(){
    if(this.timestampToggleTarget.checked){
      let newUrl = this.urlValue + "?ts=" + this.timestampInputTarget.value
      this.urlInputTarget.value = newUrl
      this.copyButtonTarget.dataset.clipboardText = newUrl
    } else {
      this.urlInputTarget.value = this.urlValue
      this.copyButtonTarget.dataset.clipboardText = this.urlValue
    }
  }

}
