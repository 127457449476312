import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"

export default class extends Controller {

  static values = {
    publicKey: String,
    locale: String,
    cards: Array,
    cardId: String,
  }

  static targets = ["errorContainer", "errorText"]

  connect() {
    Loggable(this, {debug: false})

    this._mp = new MercadoPago(this.publicKeyValue, {
      locale: this.localeValue
    });

    this._mp.fields.create('securityCode', {
      placeholder: "CVV"
    }).mount("cvv-container");

    this.appendCardToSelect()

    this.element.addEventListener('submit', e => this.createCardToken(e));

    this.log("connected")
  }

  async createCardToken(event) {
    try {
      const tokenElement = document.getElementById('token');
      if (!tokenElement.value) {
        event.preventDefault();
        let cardId = document.getElementById('card-select').value
        this.log("creating card token for card id: ", cardId)
        const token = await this._mp.fields.createCardToken({ cardId: cardId });
        this.log('token created: ', token.id)
        tokenElement.value = token.id;
        this.log("tokenElement", tokenElement)
        this.hideError()
        this.element.submit();
      }
    } catch (e) {
      this.showError(e.message)
      this.log('error creating card token: ', e)
    }
  }

  appendCardToSelect() {
    const selectElement = document.getElementById('card-select');
    const tmpFragment = document.createDocumentFragment();
    this.cardsValue.forEach(({id, name}) => {
      const optionElement = document.createElement('option');
      optionElement.setAttribute('value', id)
      optionElement.textContent = name
      if (this.cardIdValue === id) {
        optionElement.setAttribute('selected', 'selected')
      }
      tmpFragment.appendChild(optionElement);
    })
    selectElement.appendChild(tmpFragment)
  }

  hideError() {
    this.errorContainerTarget.classList.remove("show")
  }

  showError(message) {
    this.errorTextTarget.innerHTML = message
    this.errorContainerTarget.classList.add("show")
  }
}
