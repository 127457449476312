/**
 * This is a mixin that provides interval-related methods to a controller.
 * @param {Object} controller - The controller to which the methods will be added.
 * @param {Object} options - Additional options for the mixin.
 *
 *  PARA AGREGAR UN NUEVO TIPO DE INTERVAL:
 *   - crear el controller en app/javascript/controllers que herede de IntervalController
 *   - agregarlo en app/javascript/controllers/index.js
 *   - agregar su identificar a static outlets en app/javascript/controllers/playlist_controller.js
 *   - considerarlo como posible valor de interval_type en Timer::Interval#duration_in_seconds
 *   - agregar data-playlist-basic-interval-outlet en el html del playlist
 *   - agregar su identifier al IntervalHelper
 *   - agregar un partial con su configuración en intervals/_player_config
 */
export const HasIntervalOutlets = (controller, options = {}) => {
  const defaultOptions = {}
  const hasIntervalOutletsOptions = Object.assign({}, defaultOptions, options)

  var sortedIntervals

  /**
   * This method loads and sorts the intervals from the controller.
   * It should be called once per start() in case there were changes.
   * @returns {Array} The sorted intervals.
   */
  const loadIntervals = () => {
    sortedIntervals = [
      ...controller.metronomeOutlets,
      ...controller.basicIntervalOutlets,
      ...controller.loopIntervalOutlets,
      ...controller.asanaIntervalOutlets,
    ].sort((a, b) => a.positionValue - b.positionValue)
    return sortedIntervals
  }

  /**
   * This method returns the count of intervals.
   * @returns {Number} The count of intervals.
   */
  const intervalsCount = () => {
    return sortedIntervals.length
  }

  /**
   * This method returns the interval at the given index.
   * @param {Number} index - The index of the interval to return.
   * @returns {Object} The interval at the given index.
   */
  const getInterval = (index) => {
    return sortedIntervals[index]
  }

  /**
   * This method returns the current interval.
   * @returns {Object} The current interval.
   */
  const currentInterval = () => {
    return getInterval(controller.intervalIndex)
  }

  const nextInterval = () => {
    return getInterval(controller.intervalIndex + 1)
  }

  /**
   * This method checks if the current interval is the last one.
   * @returns {Boolean} True if the current interval is the last one, false otherwise.
   */
  const onLastInterval = () => {
    return controller.intervalIndex === intervalsCount() - 1
  }

  // Add the methods and options to the controller
  Object.assign(controller, {
    loadIntervals,
    getInterval,
    currentInterval,
    nextInterval,
    intervalsCount,
    onLastInterval,
    hasIntervalOutletsOptions
  })
}
