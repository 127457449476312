import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "container" ]

  initialize() {
  }
  connect() {
    this.showQR()
  }

  showQR() {
    this.log("showing qr")
    new QRCode(this.containerTarget, {
      text: this.data.get("text"),
      width: this.data.get("width"),
      height: this.data.get("height"),
      colorDark: "#000000",
      colorLight: "#ffffff",
    })._el;
  }

  log(msg){
    console.log("[qr] "+msg)
  }


}
