import Timeago from "stimulus-timeago"
import { es, enUS, pt } from "date-fns/locale"

export default class extends Timeago {

  static values = {
    locale: String,
  }

  connect() {
    super.connect()
  }

  // You can override this getter to change the locale.
  // Don't forget to import it.
  get locale() {
    switch (this.localeValue) {
      case "es":
        return es
      case "en":
        return enUS
      case "pt":
        return pt
      default:
        return enUS
    }
  }

}
